import { useState } from 'react';
import '../assets/css/style.css';

const SearchBar = ({updateSearchValue}) => {
  const [searchValue, setSearchValue] = useState('');

  const handleKeyPress = (event) => {
    if(event.key === 'Enter'){
      updateSearchValue(searchValue);
    }
  };

  return (
    <div className="search-bar">
      <input
        type="text"
        className='input-search'
        placeholder="SEARCH GAMES, ITEMS OR USERS..."
        value={searchValue}
        onChange={(e) => {setSearchValue(e.target.value)}}
        onKeyPress={handleKeyPress}
      />
			<button className='button-gradient-1' style={{ height: '45px' }} onClick={ () => {updateSearchValue(searchValue)} }>Search</button>
    </div>
  );
};
export default SearchBar;
