import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import '../App.css';
import SideMenu from '../component/SideMenu';
import Header from '../component/Header';
import PageTitle from '../component/PageTitle';
import SearchBar from '../component/SearchBar';
import CharacterSelector from '../component/CharacterSelector';
import PlayerSkills from '../component/PlayerSkills';
import PlayerListedInventories from '../component/PlayerListedInventories';

function Skill() {

	const { playfabId } = useSelector(state => state.login);
	const [zbitWalletInfo] = useState(null); // Initialize zbit wallet info
	const [tab, setTab] = useState('YourSkills');

	const updateSearchValue = (searchValue) => { };

	return (
		<div className="App">
			{/* Side Menu */}
			<SideMenu zbitWalletInfo={zbitWalletInfo} />
			{/* Main Content */}
			<div className="main-content">
				<Header />
				<div className="row m-0 p-0">
					<div className="col-sm-6 col-md-8">
						<SearchBar updateSearchValue={updateSearchValue} />
					</div>
					<div className="col-sm-6 col-md-4 text-end p-0">
						<CharacterSelector />
					</div>
				</div>
				<PageTitle />
				{playfabId ? <>
					<button className={`btn-tab me-2 ${tab === "YourSkills" ? "active" : ""}`} onClick={() => { setTab('YourSkills') }}>Your Skills</button>
					<button className={`btn-tab me-2 ${tab === "ListedSkills" ? "active" : ""}`} onClick={() => { setTab('ListedSkills') }}>Listed Skills</button>
					{
						tab === 'YourSkills' ? (
							<PlayerSkills />
						) : (
							<PlayerListedInventories type='skill' />
						)
					}</> : <h5 className='m-3 mt-4'>Please log in first!</h5>}
			</div>
		</div>
	);
}

export default Skill;
