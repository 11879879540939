import React, { useState } from 'react';
import '../App.css';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';

function Login() {
  const dispatch = useDispatch();
  const [accountInfo, setAccountInfo] = useState({
    username: '',
    password: ''
  });

  const handleChange = (e) => {
    setAccountInfo({
      ...accountInfo,
      [e.target.name]: e.target.value
    });
  };

  const handleLogin = () => {
    // console.log(accountInfo);
    if (accountInfo.username.length === 0 || accountInfo.password.length === 0) {
      toast('Error: Enter username or password.');
      return false;
    }

    window.PlayFab.settings.titleId = 'FA008';
    try {
      if (accountInfo.username.endsWith(".com")) {
        const request = {
          Email: accountInfo.username,
          Password: accountInfo.password
        };
        // console.log("LoginWithEmailAddress:", request);
        window.PlayFab.ClientApi.LoginWithEmailAddress(request, (result, error) => {
          if (error) {
            console.log(error.errorMessage);
            toast(error.errorMessage);
          } else {
            // console.log(result);
            if (result.code === 200) {
              dispatch({ type: 'SET_LOGIN_PLAYFAB_ID', payload: result.data.PlayFabId });
            }
            else {
              toast('Error: ' + result.error);
            }
          }
        });
      }
      else {
        const request = {
          Username: accountInfo.username,
          Password: accountInfo.password
        };
        window.PlayFab.ClientApi.LoginWithPlayFab(request, (result, error) => {
          if (error) {
            toast(error.errorMessage);
          } else {
            // console.log(result);
            if (result.code === 200) {
              dispatch({ type: 'SET_LOGIN_PLAYFAB_ID', payload: result.data.PlayFabId });
            }
            else {
              toast('Error: ' + result.error);
            }
          }
        });
      }
    } catch (error) {
      console.log(error)
      toast('Error: ' + error?.message);
    }
  };

  return (
    <div className='App auth-page'>
      <img className='auth-logo mt-1 ms-1 mt-sm-5 ms-sm-5' src='/images/logo.png' alt='logo' />
      <div className="auth-content">
        <div className="auth-box" style={{ maxWidth: '450px' }}>
          <label className='box-title'>Login to PWER</label>
          <label className='box-subtitle'>EPIC WEB3 GAMING</label>
          <input
            type='text'
            className='common-input-1 mt-3'
            style={{ width: '100%' }}
            placeholder='USER NAME'
            autoComplete="off"
            name="username"
            value={accountInfo.name}
            onChange={handleChange}
          />
          <input
            type='password'
            className='common-input-2 mt-2'
            style={{ width: '100%' }}
            placeholder='PASSWORD'
            autoComplete="new-password"
            name="password"
            value={accountInfo.password}
            onChange={handleChange}
          />
          <button className='auth-button mt-3' onClick={handleLogin}>LOGIN</button>
        </div>
        <div className='text-center mt-3'>
          <Link className='auth-link' to='https://www.satoria.world/'>REGISTER TO PWER</Link>
        </div>
      </div>
    </div>
  );
}

export default Login;
