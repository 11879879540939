import React, { useState, useEffect } from 'react';
import '../App.css';
import SideMenu from '../component/SideMenu';
import Header from '../component/Header';
import SearchBar from '../component/SearchBar';
import CharacterSelector from '../component/CharacterSelector';
import PageTitle from '../component/PageTitle';
import ZbitLootItem from '../component/ZbitLootItem';
import { useSelector } from 'react-redux';
import { loadZbitLootInscriptions } from '../Api/marketplace_api';

function ZbitLoot() {
	const [zbitWalletInfo] = useState(null); // Initialize zbit wallet info
	const [zbitLootItems, setZbitLootItems] = useState(null);
	const { playfabId, currentCharacter, } = useSelector(state => state.login);
	const { } = useSelector(state => state.wallet);

	const fetchItems = () => {
		loadZbitLootInscriptions(playfabId, currentCharacter.characterId).then((result => {
			console.log(result);
			setZbitLootItems(result);
		})).catch(error => {
		});
	};

	useEffect(() => {
		fetchItems();
	}, []);

	const updateSearchValue = (searchValue) => {
	};

	return (
		<div className="App">
			{/* Side Menu */}
			<SideMenu zbitWalletInfo={zbitWalletInfo} /> {/* Pass zbit wallet info as a prop to SideMenu */}
			{/* Main Content */}
			<div className="main-content">
				<Header />
				<div className="row m-0 p-0">
					<div className="col-sm-6 col-md-8">
						<SearchBar updateSearchValue={updateSearchValue} />
					</div>
					<div className="col-sm-6 col-md-4 text-end p-0">
						<CharacterSelector />
					</div>
				</div>
				<PageTitle />
				{playfabId ? <><h6 className='m-2 mt-4'>Claimable Loots</h6>
					{
						zbitLootItems ?
							(
								<>
									{
										zbitLootItems.length === 0 ?
											(
												<div className='text-center mt-5'> No Item </div>
											)
											:
											(
												zbitLootItems.map((zbitLootItem, index) =>
												(
													<ZbitLootItem zbitLootItem={zbitLootItem} updateEvent={fetchItems} key={index} />
												)
												)
											)
									}
								</>
							)
							:
							(
								<div className='row m-0 p-0 mt-2'>
									<div className='col-sm-4 p-2'>
										<span className="sr-only">Loading Items...</span>
									</div>
									<div className='col-sm-1 p-1'>
										<div className="spinner-border text-primary" role="status"></div>
									</div>
								</div>
							)
					}</> : <h5 className='m-3 mt-4'>Please log in first!</h5>}
			</div>
		</div>
	);
}

export default ZbitLoot;
