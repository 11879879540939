import { useSelector } from 'react-redux';

const CreditBox = () => {
    const {
        creditAmount,
    } = useSelector(state => state.wallet);

    return (
        <div className='zbit-container col m-0 text-center'>
            <div className=''>
                <img src={`/images/zbit.png`} style={{ width: '9px', marginRight: '4px' }} alt="Zbit Logo" />           
                <div className='ms-2 d-inline-block'>
                    <span>{creditAmount.toFixed(2)}</span>
                    <span className="zbit-text">ZBIT</span>
                </div>
            </div>
        </div>
    );
}

export default CreditBox;