import React from 'react';
import { useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';

import '../App.css';
import SkillItem from '../component/SkillItem';

const PlayerSkills = () => {
	const [skillItems, setSkillItems] = useState([]);
	const [isLoading, setIsLoading] = useState(false);
	const { playfabId, currentCharacter } = useSelector(state => state.login);

	const fetchSkillItems = useCallback(async () => {
		setIsLoading(true);
		try {
			const response = await fetch(`https://submitscore.azurewebsites.net/api/loadcharacterskills?code=RB2XVEEilYutAao95NCHVWHgjxGGZdOaRCYQmvqtD4PlAzFumaDC3g%3D%3D`,
				{
					method: "POST",
					headers: {
						"Content-Type": "application/json",
					},
					body: JSON.stringify({ playFabID: playfabId, characterID: currentCharacter.characterId, isRaw: "true", mode: "", xp: 0 })
				}
			);
			if (!response.ok) throw new Error('Network response was not ok');
			const result = await response.json();
			if (result.isOK) {
				console.log(result);
				setSkillItems(result.skills);
			}
			else {
				console.log("empty inventory:");
				setSkillItems([]);
			}
		} catch (error) {
			console.error('Error fetching characters:', error);
			setSkillItems([]);
		}
		setIsLoading(false);
	}, [playfabId, currentCharacter]);

	useEffect(() => {
		if (currentCharacter) {
			fetchSkillItems()
		} else {
			setSkillItems([])
		}
	}, [playfabId, currentCharacter, fetchSkillItems]);

	const refreshItemList = () => {
		setSkillItems([]);
		fetchSkillItems();
	};

	return (
		<>
			{
				isLoading ? (<p className='text-center mt-5'> Loading... </p>) :
					(
						<>
							{
								skillItems.length === 0 ?
									(
										<p className='text-center mt-5'> No skill items </p>
									)
									:
									(
										<div className='mt-3'>
											{
												skillItems.map(
													(invenItem, index) =>
													(
														<SkillItem isListed={false} curSkillItem={invenItem} curStoreItem={null} updateEvent={refreshItemList} key={index} />
													)
												)
											}
										</div>
									)
							}
						</>
					)
			}
		</>
	);
};
export default PlayerSkills;
