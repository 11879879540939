const LOAD_STORE_API = "https://submitscore.azurewebsites.net/api/loadstoreitems?code=n18t9fj5YxL4buMiS5EGpOrJGp8f212mYRdVacqefA5zAzFumTd39A%3D%3D";
const LOAD_ALL_CHARACTERS_FROM_ACCOUNT = "https://submitscore.azurewebsites.net/api/loadallcharactersfromaccount?code=TfBW5247JwDUpxnZKAVrx8ulm_InqZ6R2wmIJN3iTP05AzFu57qJ6g%3D%3D";
const LOAD_INVENITEM_DATA_API = "https://submitscore.azurewebsites.net/api/loadinvenitemdata?code=QcqGmTERDyQg71NC_MIWkqbmfS9EaAfSAJqqO6HZS2yEAzFuWvcsRg%3D%3D";
const LOAD_SKILLITEM_DATA_API = "https://submitscore.azurewebsites.net/api/loadskillitemdata?code=P3hdlTLmJSxhx5VGZ_ehC7aSOF8cDmOOxhyEwq_CjcKtAzFubAG_NQ%3D%3D";
const LOAD_CHARACTER_DATA_API = "https://submitscore.azurewebsites.net/api/loadcharacterdata?code=3qkCTM89_-k7ik1TzwSH2ROT2CNIG0nHkuqw1z6Coe01AzFupl02fg%3D%3D";
const PLACE_ORDER_ON_STOREITEM_API = "https://submitscore.azurewebsites.net/api/placeorderonstoreitem?code=t6TGs3eZAqVzfG2_V2_UfgsBQgoI6B1UHifNcErpxKjqAzFuIcQFYA%3D%3D";
const PLACE_TRANSACTION_ON_STOREITEM_API = "https://submitscore.azurewebsites.net/api/placetransactiononstoreitem?code=sZOmCcPEYDghsg1pj16XrUjLLvv8Ztv7U9p3V7xF6yXEAzFulSD_iw%3D%3D";
const CHECK_TRANSACTION_STATUS_API = "https://submitscore.azurewebsites.net/api/checktransactionstatus?code=9-iUpIxb7zXaiCzYs6-xg-Zb4mgUNzdtMjTP0kC3GRctAzFu6sRkfg%3D%3D";
const ADD_USER_CREDIT_API = "https://submitscore.azurewebsites.net/api/addcredittouser?code=Ct3KPrj5eFcasQJvvxGV2w4nC1tTnJXA3_G09q0dMZMYAzFu640y9w%3D%3D";
const GET_USER_CREDIT_API = "https://submitscore.azurewebsites.net/api/getplayercreditamount?code=hjpc_cYzEKbVRf2gvY2A5Yia0_vgK5EQ18zPEltRx4KKAzFu8YVKtQ%3D%3D";
const GET_USER_ONIGIRI_API = "https://submitscore.azurewebsites.net/api/getonigiriitemcount?code=-QyijtXLVavSZGZuT6T37iQOdyVFsNm3-V37YpDxGW96AzFuoByKbQ%3D%3D";
const GET_FINALIZABLE_TRADES_API = "https://submitscore.azurewebsites.net/api/loadfinalizabletradings?code=uVnQCXtwDiR0ngIhOGDB0yzMW5umZ_DXAdA7hEkzzkSwAzFuN_nbJw%3D%3D";
const FINALIZE_API = "https://submitscore.azurewebsites.net/api/finalizeitemtrading?code=ywiiok0awNUrgc7jYB6RLYOIEDPfGAVrS1u56G1WsKSRAzFuG64UMg%3D%3D";
const BUY_ITEM_USING_CREDIT = "https://submitscore.azurewebsites.net/api/buyitemusingcredit?code=RfMG4kzoZGuPVm_wzf2uB4V2OxsP0V62GWuzXI8TiCtVAzFudBQH8g%3D%3D"
const LOAD_SHOP_LIST_API = "https://submitscore.azurewebsites.net/api/loadshoplist?code=pfrOmxJzYvbUm7aahkV1keDcs0VtGgXhZkDNEQmX1m5GAzFubk6KnQ%3D%3D";
const BUY_SHOP_ITEM_API = "https://submitscore.azurewebsites.net/api/buyitemfromshop?code=2pLJ9y4iv9X_MIhz898UP8dYcuLcIV4_3BAez-_evd9PAzFuFRKh5g%3D%3D";
const GET_USER_PROFILE = "https://submitscore.azurewebsites.net/api/getuserprofile?code=nIe6MiAzT1yvC_O1luBCpVS6cUGSQK1gWU2--FZ3jRO2AzFuOAbdiw%3D%3D";
const UPDATE_USER_PROFILE = "https://submitscore.azurewebsites.net/api/updateuserprofile?code=hcwlJN1tZe96t3o4gHgthc-BtUiXhHCaMIpW4TFfLshmAzFuHPf6DA%3D%3D";
const LOAD_TRADE_HISTORY = "https://submitscore.azurewebsites.net/api/loadalltradinghistory?code=Zuvkz8dHXRRONJr5ykycBBK2nlbrptdTzd9bERHwAgd-AzFuXV560A%3D%3D";
const GET_ITEM_CATALOG_FROM_ITEM_ID = "https://submitscore.azurewebsites.net/api/getitemcatalogfromitemid?code=LtIOpVCUk2KzYiT9mMA-lkxPHWlewfkMnfuyYXru-vRPAzFue-zF5A%3D%3D"
const LOAD_ALL_ORD_ITEMS_FROM_WALLET = "https://submitscore.azurewebsites.net/api/loadallorditemsfromwallet?code=KvJ6M5Uh_b8Z50HOPwiNzgIR1neA1T6BVxnouc3wPN4kAzFuqEm1fg%3D%3D"
const CLAIM_ORDITEM_FROM_WALLET = "https://submitscore.azurewebsites.net/api/claimorditemfromwallet?code=KE8Z1OvKsKvXnbD84_wdDs7k8TDAX--EeXGGsz4ZGxEkAzFuHpOuYg%3D%3D";
const LOAD_ALL_ORD_LANDS_FROM_WALLET = "https://submitscore.azurewebsites.net/api/loadallordlandsfromwallet?code=KGBBszaV5GFLiaGty_BORM66puNN6YB7OwxYai53x9S6AzFulJsDFg%3D%3D";
const CLAIM_ORDLAND_FROM_WALLET = "https://submitscore.azurewebsites.net/api/claimordlandfromwallet?code=4kO3ZqB53rjUxeL2wXRJT1hYsvTii83r-mcqjCfYcHpnAzFuJE7NGw%3D%3D";
const GET_SKILL_CATALOG_FROM_SKILL_ID = "https://submitscore.azurewebsites.net/api/getskillcatalogfromskillid?code=YeJcWyEgIeYTt0vW2539rV6leACWzpubhkitbDugLJd9AzFu_bixIQ%3D%3D"
const LOAD_ALL_ORD_SKILLS_FROM_WALLET = "https://submitscore.azurewebsites.net/api/loadallordskillsfromwallet?code=i96GXrpjibwVzJhmWE8Wh49ICZJIBulj8qkkR73EF1p_AzFuYJ8SSQ%3D%3D"
const CLAIM_ORD_SKILL_FROM_WALLET = "https://submitscore.azurewebsites.net/api/claimordskillfromwallet?code=BBwCVzXRNK_LMcgpqsAB8YzgDuFOOaoEEbb13ORzCt36AzFubJQD_A%3D%3D";
// const WITHDRAW_SIGNATURE_REQUEST = "https://submitscore.azurewebsites.net/api/checksignature?code=daoQt_PSC81U1xqUXe8lq9qj2cnH50SsQYaCH0kfH2OgAzFu1f1_Tg%3D%3D";
const WITHDRAW_CREDIT_REQUEST = "https://submitscore.azurewebsites.net/api/withdrawcreditrequest?code=M551pBgpmXJLLVc6vln4pEqF7s0HELhoB9IVI-MXBPpXAzFu4NQThQ%3D%3D";
const WITHDRAW_ONIGIRI_REQUEST = "https://submitscore.azurewebsites.net/api/burnonigiriitems?code=aEex8NoOo_dkh-9nLrcFSHcK5OwEmQscWM4abmdoM6_7AzFu4x1P6w%3D%3D";
const LOAD_ZBIT_LOOTS = "https://submitscore.azurewebsites.net/api/loadzbitlootinscriptions?code=Y5Q-cTl6JjZWr3faaNc43sSPpuuImp5F8WTa6qpQOms7AzFu3uL19A%3D%3D";
const WITHDRAW_ZBIT_LOOT = "https://submitscore.azurewebsites.net/api/withdrawzbitinscriptionrequest?code=UIgNrxI2lSd4xtPAFjwPmLdsis6SnwHc0A3OpWjSj1FnAzFuvI-1bw%3D%3D";
const LOAD_CREDIT_HISTORY = "https://submitscore.azurewebsites.net/api/loadcredithistory?code=JmXgBauzH4VmdpgMBjRjjBr48dg-vQhZSMJX7ev3sryeAzFuamlfQA%3D%3D";
// ORDINAL MARKETPLACE
const LOAD_ORDINAL_ITEMS_LISTED = "https://submitscore.azurewebsites.net/api/loadallordinalsfrommarket?code=0O1-z6A93n-0aWn8620dB-c1tBEZ9OjxeV377Z3D7872AzFuOBqK_w%3D%3D";
const LOAD_ORDINAL_ITEMS = `https://submitscore.azurewebsites.net/api/loadallordinalsfromwallet?code=QFPkD_c_OxBrYvuzwW04AIZhEV9xJPI2lrz9xlY3tsvdAzFuTT_plQ%3D%3D`;
const LOAD_ORDINAL_LIST_PSBT = "https://submitscore.azurewebsites.net/api/createpsbtforsaleinscription?code=7TQ51KdT6KqtWIdo6nEemVe6lh31niEp641LmYhHY7vyAzFuoXBViQ%3D%3D";
const DELIST_ORDINAL_ITEM = "https://submitscore.azurewebsites.net/api/delistordinalsaleitem?code=2oH5rpUOBEZIt73TaspC1ZF9V3I9NMvdnLt1Wf9yudCEAzFuosaxdA%3D%3D";
const UPLOAD_ORDINAL_SIGN_PSBT = "https://submitscore.azurewebsites.net/api/registerordinalsforsale?code=twFyUMzOE_dK_Aq8rn7pQfqlA8GiCmdgsf1_qGasr_s6AzFuy6V2xg%3D%3D";
const BUY_ORDINAL_ITEM = "https://submitscore.azurewebsites.net/api/buyordinalsfromplatform?code=B07979NGaHFvh05d2litNAkBh4TzBZ6upG_kDWIr9oDXAzFu6pwZ5Q%3D%3D";
const UPLOAD_ORDINAL_BUY_PSBT = "https://submitscore.azurewebsites.net/api/finalizeordinalstrading?code=QNdZ1LgvWuTPIjqvBffS1Sfo9nYuK0ZACESJZDm68XesAzFu4KB6xA%3D%3D";
// Ordinal Marketplace
export const fetchOrdinalItemsAll = (pageNum, countInPage, searchKey, searchValue = '', sort = 'NL') => {
	return new Promise(async (resolve, reject) => {
		try {
			const response = await fetch(LOAD_ORDINAL_ITEMS_LISTED,
				{
					method: "POST",
					headers: { "Content-Type": "application/json" },
					body: JSON.stringify({
						startIndex: pageNum * countInPage,
						count: countInPage,
					})
				}
			);
			if (!response.ok) {
				reject("network error");
			}
			else {
				var result = await response.json();
				if (result.isOK === true) {
					resolve(result);
				}
				else {
					reject(result.messageValue)
				}
			}

		} catch (error) {
			console.error('Error fetchOrdinalItemsAll:', error);
			reject(error);
		}
	});
};
export const fetchOrdinalItems = (pageNum, countInPage, paymentAddr, ordinalsAddr, searchKey, searchValue = '', sort = 'NL') => {
	return new Promise(async (resolve, reject) => {
		try {
			const response = await fetch(LOAD_ORDINAL_ITEMS,
				{
					method: "POST",
					headers: { "Content-Type": "application/json" },
					body: JSON.stringify({
						startIndex: pageNum * countInPage,
						count: countInPage,
						paymentAddr: paymentAddr,
						ordinalsAddr: ordinalsAddr
					})
				}
			);
			if (!response.ok) reject("network error");
			else {
				var result = await response.json();
				if (result.isOK === true) {
					resolve(result);
				}
				else {
					reject(result.messageValue)
				}
			}
		} catch (error) {
			console.error('Error fetching stores:', error);
			reject(error);
		}
	});
};
export const fetchOrdinalItemPSBT = (ordinalsAddr, ordinalsPubkey, paymentAddr, inscriptionId, price, currencyType) => {
	return new Promise(async (resolve, reject) => {
		try {
			const response = await fetch(LOAD_ORDINAL_LIST_PSBT,
				{
					method: "POST",
					headers: { "Content-Type": "application/json" },
					body: JSON.stringify({
						sellerOrdinalsAddr: ordinalsAddr,
						sellerOrdinalsPubKey: ordinalsPubkey,
						sellerPaymentAddr: paymentAddr,
						inscriptionId: inscriptionId,
						sellPrice: price,
						currencyType: currencyType
					})
				}
			);
			if (!response.ok) {
				reject("network error");
			}
			else {
				var result = await response.json();
				// console.log("fetchStoreItems:", result);
				if (result.isOK === true) {
					resolve(result);
				}
				else {
					reject(result.messageValue)
				}
			}
		} catch (error) {
			console.error('Error fetching ordinal psbt:', error);
			reject(error);
		}
	});
};
export const delistOrdinalItem = (ordinalsAddr, ordinalsSig, paymentAddr, paymentSig, sigMessage, inscriptionId) => {
	return new Promise(async (resolve, reject) => {
		try {
			const response = await fetch(DELIST_ORDINAL_ITEM,
				{
					method: "POST",
					headers: { "Content-Type": "application/json" },
					body: JSON.stringify({
						ordinalsAddr: ordinalsAddr,
						ordinalsSig: ordinalsSig,
						paymentAddr: paymentAddr,
						paymentSig: paymentSig,
						sigMessage: sigMessage,
						inscriptionId: inscriptionId
					})
				}
			);
			if (!response.ok) {
				reject("network error");
			}
			else {
				var result = await response.json();
				console.log("delistOrdinalItem:", result);
				if (result.isOK === true) {
					resolve(result);
				}
				else {
					reject(result.messageValue)
				}
			}
		} catch (error) {
			console.error('Error fetching ordinal psbt:', error);
			reject(error);
		}
	});
}
export const uploadOrdinalItemPSBT = (ordinalsAddr, paymentAddr, psbtBase64, inscriptionId, price) => {
	return new Promise(async (resolve, reject) => {
		try {
			const response = await fetch(
				UPLOAD_ORDINAL_SIGN_PSBT,
				{
					method: "POST",
					headers: { "Content-Type": "application/json" },
					body: JSON.stringify({
						sellerOrdinalsAddr: ordinalsAddr,
						sellerPaymentAddr: paymentAddr,
						inscriptionId: inscriptionId,
						psbtBase64: psbtBase64,
						sellPrice: price
					})
				}
			);
			if (!response.ok) {
				reject("network error");
			} else {
				var result = await response.json();
				if (result.isOK === true) {
					resolve(result);
				} else {
					reject(result.messageValue)
				}
			}
		} catch (error) {
			console.error('Error uploading ordinal psbt:', error);
			reject(error);
		}
	});
};
export const buyOrdinalItem = (ordinalsAddr, ordinalsPubkey, paymentAddr, paymentPubKey, inscriptionId, price) => {
	return new Promise(async (resolve, reject) => {
		try {
			const response = await fetch(BUY_ORDINAL_ITEM,
				{
					method: "POST",
					headers: { "Content-Type": "application/json" },
					body: JSON.stringify({
						buyerPaymentAddr: paymentAddr,
						buyerPaymentPubKey: paymentPubKey,
						buyerOrdinalsAddr: ordinalsAddr,
						buyerOrdinalsPubKey: ordinalsPubkey,
						inscriptionId: inscriptionId,
						buyPrice: price
					})
				}
			);
			if (!response.ok) {
				reject("network error");
			}
			else {
				var result = await response.json();
				// console.log("fetchStoreItems:", result);
				if (result.isOK === true) {
					resolve(result);
				}
				else {
					reject(result.messageValue)
				}
			}
		} catch (error) {
			console.error('Error fetching ordinal psbt:', error);
			reject(error);
		}
	});
}
export const uploadOrdinalItemBuyPSBT = (ordinalsAddr, paymentAddr, inscriptionId, psbtBase64) => {
	return new Promise(async (resolve, reject) => {
		try {
			const response = await fetch(UPLOAD_ORDINAL_BUY_PSBT,
				{
					method: "POST",
					headers: { "Content-Type": "application/json" },
					body: JSON.stringify({
						buyerOrdinalsAddr: ordinalsAddr,
						buyerPaymentAddr: paymentAddr,
						inscriptionId: inscriptionId,
						psbtBase64: psbtBase64,
					})
				}
			);
			if (!response.ok) {
				reject("network error");
			} else {
				var result = await response.json();
				if (result.isOK === true) {
					resolve(result);
				} else {
					reject(result.messageValue)
				}
			}
		} catch (error) {
			console.error('Error uploading ordinal psbt:', error);
			reject(error);
		}
	});
};
// ===================================
export const fetchStoreItems = (pageNum, countInPage, searchKey, searchValue = '', sort = 'NL') => {
	const replacedText = searchValue;
	return new Promise(async (resolve, reject) => {
		try {
			const response = await fetch(
				LOAD_STORE_API,
				{
					method: "POST",
					headers: {
						"Content-Type": "application/json",
					},
					body: JSON.stringify({
						startIndex: pageNum * countInPage,
						count: countInPage,
						searchKey: searchKey,
						searchBarKey: replacedText,
						sort: sort,
					})
				}
			);
			if (!response.ok) {
				reject("network error");
			}
			else {
				var result = await response.json();
				// console.log("fetchStoreItems:", result);
				if (result.isOK === true) {
					resolve(result);
				}
				else {
					reject(result.messageValue)
				}
			}

		} catch (error) {
			console.error('Error fetching stores:', error);
			reject(error);
		}
	});
};

export const fetchCharacters = (playfabId) => {
	return new Promise(async (resolve, reject) => {
		try {
			const response = await fetch(
				LOAD_ALL_CHARACTERS_FROM_ACCOUNT,
				{
					method: "POST",
					headers: {
						"Content-Type": "application/json",
					},
					body: JSON.stringify({
						playFabID: playfabId
					})
				}
			);

			if (!response.ok) throw new Error('Network response was not ok');
			const result = await response.json();
			if (result.isOK) {
				resolve(result.characters);
			}
			else {
				reject("Network response was not ok");
			}
		} catch (error) {
			reject(error);
		}
	});
};

export const checkTransactionStatus = (transactionId) => {
	return new Promise(async (resolve, reject) => {
		try {
			console.log("checkTransactionStatus:", transactionId);
			const response = await fetch(
				CHECK_TRANSACTION_STATUS_API,
				{
					method: "POST",
					headers: {
						"Content-Type": "application/json",
					},
					body: JSON.stringify({
						transactionId: transactionId
					})
				}
			);
			if (!response.ok) {
				reject("Network response was not ok");
			}
			else {
				const result = await response.json();
				if (result.isOK === true) {
					resolve(result.confirmed);
				}
				else {
					reject(result.messageValue);
				}
			}

		} catch (error) {
			console.error('Error checkTransactionStatus:', error);
			reject(error);
		}
	});
};

export const fetchInvenItemData = (invenId) => {
	return new Promise(async (resolve, reject) => {
		try {
			// console.log("fetchInvenItemData:", invenId);
			const response = await fetch(
				LOAD_INVENITEM_DATA_API,
				{
					method: "POST",
					headers: {
						"Content-Type": "application/json",
					},
					body: JSON.stringify({
						itemId: invenId
					})
				}
			);
			if (!response.ok) {
				reject("Network response was not ok");
			}
			else {
				const result = await response.json();
				// console.log("fetchInvenItemData result:", result);
				if (result.isOK === true) {
					resolve(result.item);
				}
				else {
					reject(result.messageValue);
				}
			}

		} catch (error) {
			console.error('Error fetchInvenItemData:', error);
			reject(error);
		}
	});
};

export const fetchSkillItemData = (skillInstanceId) => {
	return new Promise(async (resolve, reject) => {
		try {
			// console.log("fetchInvenItemData:", invenId);
			const response = await fetch(
				LOAD_SKILLITEM_DATA_API,
				{
					method: "POST",
					headers: {
						"Content-Type": "application/json",
					},
					body: JSON.stringify({
						skillInstanceId: skillInstanceId
					})
				}
			);
			if (!response.ok) {
				reject("Network response was not ok");
			}
			else {
				const result = await response.json();
				// console.log("fetchInvenItemData result:", result);
				if (result.isOK === true) {
					resolve(result.skill);
				}
				else {
					reject(result.messageValue);
				}
			}

		} catch (error) {
			console.error('Error fetchInvenItemData:', error);
			reject(error);
		}
	});
};

export const fetchCharacterData = (playFabID, characterID) => {
	return new Promise(async (resolve, reject) => {
		try {
			const response = await fetch(
				LOAD_CHARACTER_DATA_API,
				{
					method: "POST",
					headers: {
						"Content-Type": "application/json",
					},
					body: JSON.stringify({ playFabID: playFabID, characterID: characterID })
				}
			);
			if (!response.ok) {
				reject("network error");
			}
			const result = await response.json();
			// console.log("fetchOwnerData result:", result);
			if (result.isOK === true) {
				resolve(result.characterData);
			}
			else {
				reject(result.messageValue);
			}
		} catch (error) {
			console.error('Error fetching characters:', error);
			reject(error);
		}
	});
};

export const PlaceOrderIdOnStoreItem = (storeId, orderId) => {
	return new Promise(async (resolve, reject) => {
		try {
			console.log("PlaceOrderIdOnStoreItem:", storeId, orderId);
			const response = await fetch(
				PLACE_ORDER_ON_STOREITEM_API,
				{
					method: "POST",
					headers: {
						"Content-Type": "application/json",
					},
					body: JSON.stringify({ storeItemId: storeId, orderId: orderId })
				}
			);
			if (!response.ok) {
				reject("network error");
			}
			const result = await response.json();
			console.log("PlaceOrderIdOnStoreItem result:", result);
			if (result.isOK === true) {
				resolve(true);
			}
			else {
				reject(result.messageValue);
			}
		} catch (error) {
			console.error('Error fetching characters:', error);
			reject(error);
		}
	});
};

export const PlaceTransactionIdOnStoreItem = (storeId, transactionIds, playfabId, characterId) => {
	return new Promise(async (resolve, reject) => {
		try {
			console.log("PlaceTransactionIdOnStoreItem:", storeId, transactionIds, playfabId, characterId);
			const response = await fetch(
				PLACE_TRANSACTION_ON_STOREITEM_API,
				{
					method: "POST",
					headers: {
						"Content-Type": "application/json",
					},
					body: JSON.stringify({ storeItemId: storeId, transactionId: transactionIds, buyerPlayFabId: playfabId, buyerCharacterId: characterId })
				}
			);
			if (!response.ok) {
				reject("network error");
			}
			const result = await response.json();
			console.log("PlaceOrderIdOnStoreItem result:", result);
			if (result.isOK === true) {
				resolve(true);
			}
			else {
				reject(result.messageValue);
			}
		} catch (error) {
			console.error('Error fetching characters:', error);
			reject(error);
		}
	});
};

export const addUserCredit = (playfabId, characterId, ordinalsAddr, txId) => {
	return new Promise(async (resolve, reject) => {
		try {
			console.log("AddUserCredit:", playfabId, characterId, txId);
			const response = await fetch(
				ADD_USER_CREDIT_API,
				{
					method: "POST",
					headers: {
						"Content-Type": "application/json",
					},
					body: JSON.stringify({
						playFabID: playfabId,
						characterID: characterId,
						ordinalsAddr: ordinalsAddr,
						txId: txId
					})
				}
			);
			if (!response.ok) {
				reject("network error");
			}
			const result = await response.json();
			console.log("AddUserCredit result:", result);
			if (result.isOK === true) {
				resolve(true);
			}
			else {
				reject(result.messageValue);
			}
		} catch (error) {
			console.error('Error fetching characters:', error);
			reject(error);
		}
	});
};

export const getUserCredit = (playfabId, characterId) => {
	return new Promise(async (resolve, reject) => {
		try {
			const response = await fetch(
				GET_USER_CREDIT_API,
				{
					method: "POST",
					headers: {
						"Content-Type": "application/json",
					},
					body: JSON.stringify({ playFabID: playfabId, characterID: characterId })
				}
			);
			if (!response.ok) {
				reject("network error");
			}
			const result = await response.json();
			// console.log("GetUserCredit result:", result);
			if (result.isOK === true) {
				resolve(result.result);
			}
			else {
				reject(result.messageValue);
			}
		} catch (error) {
			console.error('Error fetching characters:', error);
			reject(error);
		}
	});
};

export const getUserOnigiri = (playfabId, characterId) => {
	return new Promise(async (resolve, reject) => {
		try {
			const response = await fetch(
				GET_USER_ONIGIRI_API,
				{
					method: "POST",
					headers: {
						"Content-Type": "application/json",
					},
					body: JSON.stringify({ playFabID: playfabId, characterID: characterId })
				}
			);
			if (!response.ok) {
				reject("network error");
			}
			const result = await response.json();
			// console.log("GetUserOnigiri result:", result);
			if (result.isOK === true) {
				resolve(result.count);
			}
			else {
				reject(result.messageValue);
			}
		} catch (error) {
			console.error('Error fetching characters:', error);
			reject(error);
		}
	});
};

export const getFinalizableTrades = (playfabId, characterId) => {
	return new Promise(async (resolve, reject) => {
		try {
			console.log("GetFinalizableTrades: ", playfabId, characterId);
			const response = await fetch(
				GET_FINALIZABLE_TRADES_API,
				{
					method: "POST",
					headers: {
						"Content-Type": "application/json",
					},
					body: JSON.stringify({ playFabID: playfabId, characterID: characterId })
				}
			);
			if (!response.ok) {
				reject("network error");
			}
			const result = await response.json();
			console.log("GetFinalizableTrades result:", result);
			if (result.isOK === true) {
				resolve(result.storeItems);
			}
			else {
				reject(result.messageValue);
			}
		} catch (error) {
			console.error('Error fetching finalizable trades:', error);
			reject(error);
		}
	});
};

export const finalizeTrade = (storeItemId) => {
	return new Promise(async (resolve, reject) => {
		try {
			console.log("FinalizeTrade: ", storeItemId);
			const response = await fetch(
				FINALIZE_API,
				{
					method: "POST",
					headers: {
						"Content-Type": "application/json",
					},
					body: JSON.stringify({ storeItemId: storeItemId })
				}
			);
			if (!response.ok) {
				reject("network error");
			}
			const result = await response.json();
			console.log("FinalizeTrade result:", result);
			if (result.isOK === true) {
				resolve(true);
			}
			else {
				reject(result.messageValue);
			}
		} catch (error) {
			console.error('Error FinalizeTrade:', error);
			reject(error);
		}
	});
};

export const buyItemUsingCredit = (storeItemId, buyerPlayFabId, buyerCharacterId, uiPrice) => {
	return new Promise(async (resolve, reject) => {
		try {
			// console.log("BuyItemUsingCredit: ", storeItemId, buyerPlayFabId, buyerCharacterId, storeItemId);
			const response = await fetch(
				BUY_ITEM_USING_CREDIT,
				{
					method: "POST",
					headers: {
						"Content-Type": "application/json",
					},
					body: JSON.stringify({ storeItemId: storeItemId, buyerPlayFabId: buyerPlayFabId, buyerCharacterId: buyerCharacterId, uiPrice: uiPrice })
				}
			);
			if (!response.ok) {
				reject("network error");
			}
			const result = await response.json();
			// console.log("BuyItemUsingCredit result:", result);
			if (result.isOK === true) {
				resolve(true);
			}
			else {
				reject(result.messageValue);
			}
		} catch (error) {
			console.error('Error BuyItemUsingCredit:', error);
			reject(error);
		}
	});
};

export const fetchShopItems = (pageNum, countInPage, searchKey, searchValue = '') => {
	const replacedText = searchValue.replace(/ /g, "|");
	return new Promise(async (resolve, reject) => {
		try {
			const response = await fetch(
				LOAD_SHOP_LIST_API,
				{
					method: "POST",
					headers: {
						"Content-Type": "application/json",
					},
					body: JSON.stringify({
						startIndex: pageNum * countInPage,
						count: countInPage,
						searchKey: searchKey,
						searchBarKey: replacedText,
					})
				}
			);
			if (!response.ok) {
				reject("network error");
			}
			else {
				var result = await response.json();
				// console.log("fetchShopItems:", result);
				if (result.isOK === true) {
					resolve(result);
				}
				else {
					reject(result.messageValue)
				}
			}

		} catch (error) {
			console.error('Error fetching shop items:', error);
			reject(error);
		}
	});
};

export const buyShopItem = (shopInstanceId, count, buyerPlayFabId, buyerCharacterId) => {
	return new Promise(async (resolve, reject) => {
		try {
			console.log("BuyShopItem: ", shopInstanceId);
			const response = await fetch(
				BUY_SHOP_ITEM_API,
				{
					method: "POST",
					headers: {
						"Content-Type": "application/json",
					},
					body: JSON.stringify({
						shopInstanceId: shopInstanceId,
						count: count,
						playFabID: buyerPlayFabId,
						characterID: buyerCharacterId
					})
				}
			);
			if (!response.ok) {
				reject("network error");
			}
			const result = await response.json();
			console.log("BuyShopItem result:", result);
			if (result.isOK === true) {
				resolve(true);
			}
			else {
				reject(result.messageValue);
			}
		} catch (error) {
			console.error('Error BuyShopItem:', error);
			reject(error);
		}
	});
};

export const getUserProfile = (playFabID) => {
	return new Promise(async (resolve, reject) => {
		try {
			const response = await fetch(
				GET_USER_PROFILE,
				{
					method: "POST",
					headers: {
						"Content-Type": "application/json",
					},
					body: JSON.stringify({
						playFabID: playFabID,
					})
				}
			);
			if (!response.ok) {
				reject("network error");
			}
			const result = await response.json();
			if (result.isOK === true) {
				resolve(result.profile);
			}
			else {
				reject(result.messageValue);
			}
		} catch (error) {
			console.error('Error GetUserProfile:', error);
			reject(error);
		}
	});
};

export const updateUserProfile = (playFabID, walletAddress) => {
	return new Promise(async (resolve, reject) => {
		try {
			const response = await fetch(
				UPDATE_USER_PROFILE,
				{
					method: "POST",
					headers: {
						"Content-Type": "application/json",
					},
					body: JSON.stringify({
						playFabID: playFabID,
						walletAddr: walletAddress,
					})
				}
			);
			if (!response.ok) {
				reject("network error");
			}
			const result = await response.json();
			if (result.isOK === true) {
				resolve(result);
			}
			else {
				reject(result.messageValue);
			}
		} catch (error) {
			console.error('Error UpdateUserProfile:', error);
			reject(error);
		}
	});
};

export const fetchTradeHistoryies = (characterID, pageNum, countInPage, searchKey) => {
	const replacedText = searchKey.replace(/ /g, "|");
	return new Promise(async (resolve, reject) => {
		try {
			const response = await fetch(
				LOAD_TRADE_HISTORY,
				{
					method: "POST",
					headers: {
						"Content-Type": "application/json",
					},
					body: JSON.stringify({
						// playFabID: playFabID,
						characterID: characterID,
						startIndex: pageNum * countInPage,
						count: countInPage,
						searchKey: replacedText,
					})
				}
			);
			if (!response.ok) {
				reject("network error");
			}
			else {
				var result = await response.json();
				// console.log("fetchTradeHistoryies:", result);
				if (result.isOK === true) {
					resolve(result);
				}
				else {
					reject(result.messageValue)
				}
			}

		} catch (error) {
			console.error('Error fetchTradeHistoryies:', error);
			reject(error);
		}
	});
};

export const fetchCreditHistoryies = (playFabID, characterID, pageNum, countInPage, searchKey) => {

	return new Promise(async (resolve, reject) => {
		try {
			const response = await fetch(
				LOAD_CREDIT_HISTORY,
				{
					method: "POST",
					headers: {
						"Content-Type": "application/json",
					},
					body: JSON.stringify({
						playFabID: playFabID,
						startIndex: pageNum * countInPage,
						count: countInPage,
					})
				}
			);
			if (!response.ok) {
				reject("network error");
			}
			else {
				var result = await response.json();
				console.log("fetchCreditHistoryies:", result);
				if (result.isOK === true) {
					resolve(result);
				}
				else {
					reject(result.messageValue)
				}
			}

		} catch (error) {
			console.error('Error fetchCreditHistoryies:', error);
			reject(error);
		}
	});
};

export const getItemCatalog = (itemID) => {
	return new Promise(async (resolve, reject) => {
		try {
			const response = await fetch(
				GET_ITEM_CATALOG_FROM_ITEM_ID,
				{
					method: "POST",
					headers: {
						"Content-Type": "application/json",
					},
					body: JSON.stringify({
						itemID: itemID,
					})
				}
			);
			if (!response.ok) {
				reject("network error");
			}
			const result = await response.json();
			if (result.isOK === true) {
				resolve(result.catalog);
			}
			else {
				reject(result.messageValue);
			}
		} catch (error) {
			console.error('Error GetItemCatalog:', error);
			reject(error);
		}
	});
};

export const LoadInscriptionGameItemsFromWalletAddr = (ordinalsAddr, ordinalsSig, paymentAddr, paymentSig, sigMessage) => {
	return new Promise(async (resolve, reject) => {
		try {
			const response = await fetch(
				LOAD_ALL_ORD_ITEMS_FROM_WALLET,
				{
					method: "POST",
					headers: {
						"Content-Type": "application/json",
					},
					body: JSON.stringify({
						ordinalsAddr: ordinalsAddr,
						paymentAddr: paymentAddr,
						ordinalsSig: ordinalsSig,
						paymentSig: paymentSig,
						sigMessage: sigMessage,
					})
				}
			);
			if (!response.ok) {
				reject("network error");
			}
			const result = await response.json();
			if (result.isOK === true) {
				resolve(result.items);
			}
			else {
				reject(result.messageValue);
			}
		} catch (error) {
			console.error('Error GetItemCatalog:', error);
			reject(error);
		}
	});
};

export const claimOrdItemFromWallet = (ordInstanceId, playFabID, characterID) => {
	console.log(ordInstanceId, playFabID, characterID)
	return new Promise(async (resolve, reject) => {
		try {
			const response = await fetch(
				CLAIM_ORDITEM_FROM_WALLET,
				{
					method: "POST",
					headers: {
						"Content-Type": "application/json",
					},
					body: JSON.stringify({
						ordInstanceId: ordInstanceId,
						playFabID: playFabID,
						characterID: characterID,
					})
				}
			);
			if (!response.ok) {
				reject("network error");
			}
			const result = await response.json();
			if (result.isOK === true) {
				resolve(true);
			}
			else {
				reject(result.messageValue);
			}
		} catch (error) {
			console.error('Error GetItemCatalog:', error);
			reject(error);
		}
	});
};

export const loadAllOrdLandsFromWallet = (ordinalsAddr, ordinalsSig, paymentAddr, paymentSig, sigMessage) => {
	return new Promise(async (resolve, reject) => {
		try {
			const response = await fetch(
				LOAD_ALL_ORD_LANDS_FROM_WALLET,
				{
					method: "POST",
					headers: {
						"Content-Type": "application/json",
					},
					body: JSON.stringify({
						ordinalsAddr: ordinalsAddr,
						paymentAddr: paymentAddr,
						ordinalsSig: ordinalsSig,
						paymentSig: paymentSig,
						sigMessage: sigMessage,
					})
				}
			);
			if (!response.ok) {
				reject("network error");
			}
			const result = await response.json();
			if (result.isOK === true) {
				resolve(result.lands);
			}
			else {
				reject(result.messageValue);
			}
		} catch (error) {
			console.error('Error GetItemCatalog:', error);
			reject(error);
		}
	});
};

export const claimOrdLandItemFromWallet = (ordInstanceId, playFabID, characterID) => {
	console.log(ordInstanceId, playFabID, characterID)
	return new Promise(async (resolve, reject) => {
		try {
			const response = await fetch(
				CLAIM_ORDLAND_FROM_WALLET,
				{
					method: "POST",
					headers: {
						"Content-Type": "application/json",
					},
					body: JSON.stringify({
						ordInstanceId: ordInstanceId,
						playFabID: playFabID,
						characterID: characterID,
					})
				}
			);
			if (!response.ok) {
				reject("network error");
			}
			const result = await response.json();
			if (result.isOK === true) {
				resolve(true);
			}
			else {
				reject(result.messageValue);
			}
		} catch (error) {
			console.error('Error GetItemCatalog:', error);
			reject(error);
		}
	});
};

export const getSkillCatalogFromSkillId = (skillId) => {
	return new Promise(async (resolve, reject) => {
		try {
			const response = await fetch(
				GET_SKILL_CATALOG_FROM_SKILL_ID,
				{
					method: "POST",
					headers: {
						"Content-Type": "application/json",
					},
					body: JSON.stringify({
						skillId: skillId,
					})
				}
			);
			if (!response.ok) {
				reject("network error");
			}
			const result = await response.json();
			if (result.isOK === true) {
				resolve(result.catalog);
			}
			else {
				reject(result.messageValue);
			}
		} catch (error) {
			console.error('Error GetItemCatalog:', error);
			reject(error);
		}
	});
};

export const LoadInscriptionOrdinalsSkillsFromWalletAddr = (ordinalsAddr, ordinalsSig, paymentAddr, paymentSig, sigMessage) => {
	return new Promise(async (resolve, reject) => {
		try {
			const response = await fetch(
				LOAD_ALL_ORD_SKILLS_FROM_WALLET,
				{
					method: "POST",
					headers: {
						"Content-Type": "application/json",
					},
					body: JSON.stringify({
						ordinalsAddr: ordinalsAddr,
						paymentAddr: paymentAddr,
						ordinalsSig: ordinalsSig,
						paymentSig: paymentSig,
						sigMessage: sigMessage,
					})
				}
			);
			if (!response.ok) {
				reject("network error");
			}
			const result = await response.json();
			if (result.isOK === true) {
				resolve(result.skills);
			}
			else {
				reject(result.messageValue);
			}
		} catch (error) {
			console.error('Error GetItemCatalog:', error);
			reject(error);
		}
	});
};

export const claimOrdinalsSkillFromWallet = (ordInstanceId, playFabID, characterID) => {
	console.log(ordInstanceId, playFabID, characterID)
	return new Promise(async (resolve, reject) => {
		try {
			const response = await fetch(
				CLAIM_ORD_SKILL_FROM_WALLET,
				{
					method: "POST",
					headers: {
						"Content-Type": "application/json",
					},
					body: JSON.stringify({
						ordInstanceId: ordInstanceId,
						playFabID: playFabID,
						characterID: characterID,
					})
				}
			);
			if (!response.ok) {
				reject("network error");
			}
			const result = await response.json();
			if (result.isOK === true) {
				resolve(true);
			}
			else {
				reject(result.messageValue);
			}
		} catch (error) {
			console.error('Error GetItemCatalog:', error);
			reject(error);
		}
	});
};

// export const withdrawSignatureRequest = (ordinalsAddress, paymentAddress) => {
// 	return new Promise(async (resolve, reject) => {
// 		try {
// 			// console.log("WithdrawCreditRequest:", playfabId, amount, feeRate, ordinalsAddr, paymentAddr, txId);
// 			const response = await fetch(
// 				WITHDRAW_SIGNATURE_REQUEST,
// 				{
// 					method: "POST",
// 					headers: {
// 						"Content-Type": "application/json",
// 					},
// 					body: JSON.stringify({
// 						paymentAddress: paymentAddress,
// 						ordinalsAddress: ordinalsAddress,
// 					})
// 				}
// 			);
// 			if (!response.ok) {
// 				reject("network error");
// 			}
// 			const result = await response.json();
// 			// console.log("WithdrawCreditRequest result:", result);
// 			if (result.isOK === true) {
// 				resolve(result.signature);
// 			}
// 			else {
// 				reject(result.signature);
// 			}
// 		} catch (error) {
// 			console.error('Error fetching characters:', error);
// 			reject(error);
// 		}
// 	});
// }

export const withdrawCreditRequest = (playfabId, amount, feeRate, ordinalsAddr, ordinalsSig, paymentAddr, paymentSig, sigMessage, txId) => {
	return new Promise(async (resolve, reject) => {
		try {
			console.log("WithdrawCreditRequest:", playfabId, amount, feeRate, ordinalsAddr, ordinalsSig, paymentAddr, paymentSig, txId);
			const response = await fetch(
				WITHDRAW_CREDIT_REQUEST,
				{
					method: "POST",
					headers: {
						"Content-Type": "application/json",
					},
					body: JSON.stringify({
						playFabID: playfabId,
						amount: amount,
						feeRate: feeRate,
						ordinalsAddr: ordinalsAddr,
						paymentAddr: paymentAddr,
						ordinalsSig: ordinalsSig,
						paymentSig: paymentSig,
						sigMessage: sigMessage,
						txId: txId
					})
				}
			);
			if (!response.ok) {
				reject("network error");
			}
			const result = await response.json();
			// console.log("WithdrawCreditRequest result:", result);
			if (result.isOK === true) {
				resolve(result.transaction);
			}
			else {
				reject(result.messageValue);
			}
		} catch (error) {
			console.error('Error fetching characters:', error);
			reject(error);
		}
	});
};

export const withdrawOnigiriRequest = (playfabId, characterId, amount, feeRate, ordinalsAddr, ordinalsSig, paymentAddr, paymentSig, sigMessage, txId) => {
	return new Promise(async (resolve, reject) => {
		try {
			// console.log("WithdrawOnigiriRequest:", playfabId, amount, feeRate, ordinalsAddr, paymentAddr, txId);
			const response = await fetch(
				WITHDRAW_ONIGIRI_REQUEST,
				{
					method: "POST",
					headers: {
						"Content-Type": "application/json",
					},
					body: JSON.stringify({
						playFabID: playfabId,
						characterID: characterId,
						amount: amount,
						feeRate: feeRate,
						ordinalsAddr: ordinalsAddr,
						paymentAddr: paymentAddr,
						ordinalsSig: ordinalsSig,
						paymentSig: paymentSig,
						sigMessage: sigMessage,
						txId: txId
					})
				}
			);
			if (!response.ok) {
				reject("network error");
			}
			const result = await response.json();
			// console.log("WithdrawOnigiriRequest result:", result);
			if (result.isOK === true) {
				resolve(result.transaction);
			}
			else {
				reject(result.messageValue);
			}
		} catch (error) {
			console.error('Error fetching characters:', error);
			reject(error);
		}
	});
};

export const loadZbitLootInscriptions = (playfabId, characterId) => {
	return new Promise(async (resolve, reject) => {
		try {
			console.log("LoadZbitLootInscriptions:", playfabId, characterId);
			const response = await fetch(
				LOAD_ZBIT_LOOTS,
				{
					method: "POST",
					headers: {
						"Content-Type": "application/json",
					},
					body: JSON.stringify({
						playFabID: playfabId,
						characterID: characterId
					})
				}
			);
			if (!response.ok) {
				reject("network error");
			}
			const result = await response.json();
			console.log("LoadZbitLootInscriptions result:", result);
			if (result.isOK === true) {
				resolve(result.inscriptions);
			}
			else {
				reject(result.messageValue);
			}
		} catch (error) {
			console.error('Error fetching characters:', error);
			reject(error);
		}
	});
};

export const withdrawZbitLoot = (playfabId, characterId, lootWinnerId, feeRate, ordinalsAddr, paymentAddr, txId) => {
	return new Promise(async (resolve, reject) => {
		try {
			console.log("withdrawZbitLoot:", playfabId, lootWinnerId, feeRate, ordinalsAddr, paymentAddr, txId);
			const response = await fetch(
				WITHDRAW_ZBIT_LOOT,
				{
					method: "POST",
					headers: {
						"Content-Type": "application/json",
					},
					body: JSON.stringify({
						playFabID: playfabId,
						characterID: characterId,
						lootWinnerId: lootWinnerId,
						feeRate: feeRate,
						ordinalsAddr: ordinalsAddr,
						paymentAddr: paymentAddr,
						txId: txId
					})
				}
			);
			if (!response.ok) {
				reject("network error");
			}
			const result = await response.json();
			console.log("withdrawZbitLoot result:", result);
			if (result.isOK === true) {
				resolve(result.transaction);
			}
			else {
				reject(result.messageValue);
			}
		} catch (error) {
			console.error('Error fetching characters:', error);
			reject(error);
		}
	});
};