import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import ReactPaginate from 'react-paginate';

import { fetchCreditHistoryies } from '../Api/marketplace_api';
import PageTitle from './PageTitle';
import HistoryItem from './HistoryItem';
import SearchBar from './SearchBar';
import CharacterSelector from './CharacterSelector';
import '../assets/css/style.css';

const HistoryPanel = () => {
	const { playfabId, currentCharacter } = useSelector(state => state.login);
	const [histories, setHistories] = useState([]);
	const [totalCount, setTotalCount] = useState(0);
	const [pageSize] = useState(100);
	const [pageNum, setPageNum] = useState(0);
	const [searchValue, setSearchValue] = useState('');
	const [isLoading, setIsLoading] = useState(false);

	const refreshCurrentPage = useCallback(() => {
		setIsLoading(true);
		setHistories([]);

		fetchCreditHistoryies(playfabId, currentCharacter.characterId, pageNum, pageSize, searchValue).then((result) => {
			setHistories(result.history);
			setTotalCount(result.totalCount);
			setIsLoading(false);
		})
			.catch(error => {
				console.log("error in fetchStoreItems:", error);
				setIsLoading(false);
			});
	}, [playfabId, currentCharacter, pageNum, pageSize, searchValue]);

	useEffect(() => {
		refreshCurrentPage();
	}, [refreshCurrentPage]);

	const handlePageClick = (event) => {
		setHistories([]);
		setPageNum(event.selected);
	};

	const updateSearchValue = (searchValue) => {
		setSearchValue(searchValue);
	};

	return (
		<>
			<div className="row m-0 p-0">
				<div className="col-sm-6 col-md-8">
					<SearchBar updateSearchValue={updateSearchValue} />
				</div>
				<div className="col-sm-6 col-md-4 text-end p-0">
					<CharacterSelector />
				</div>
			</div>

			<PageTitle />

			<h6 className='m-2 mt-4'>History</h6>

			<div className='pagination-div mt-3 ms-2' >
				<ReactPaginate
					breakLabel="..."
					nextLabel="next >"
					onPageChange={handlePageClick}
					pageRangeDisplayed={5}
					pageCount={parseInt((totalCount - 1) / pageSize) + 1}
					previousLabel="< prev"
					renderOnZeroPageCount={null}
				/>
			</div>

			<div className='mt-3'>
				{isLoading ?
					(
						<div className='text-center mt-5'> Loading Items ...</div>
					) :
					(
						histories.length === 0 ?
							(
								<div className='text-center mt-5'> No Item </div>
							)
							:
							(
								histories.map((item, index) =>
								(
									<HistoryItem historyItem={item} key={index} />
								)
								)
							)
					)
				}
			</div>
		</>
	);

}

export default HistoryPanel;