const initialState = {
    playfabId: null,
    currentCharacter: null
};

const loginReducer = (state = initialState, action) => {
    switch (action.type) {        
        case 'SET_LOGIN_PLAYFAB_ID':
            return {
                ...state,
                playfabId: action.payload
            };
        case 'SET_CURRENT_CHARACTER':
            return {
                ...state,
                currentCharacter: action.payload
            };
        default:
            return state;
    }
};

export default loginReducer;