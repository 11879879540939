import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal, Image } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';

import ItemProps from './itemprops';
import ImageWithFallback from './ImageWithFallback';
import { fetchInvenItemData, fetchSkillItemData, fetchCharacterData, buyItemUsingCredit, getUserCredit } from '../Api/marketplace_api';
import '../assets/css/style.css'

const StoreItemCard = ({ storeItem, updateEvent }) => {
	const dispatch = useDispatch();
	const {
		creditAmount,
	} = useSelector(state => state.wallet);
	const {
		playfabId,
		currentCharacter,
	} = useSelector(state => state.login);
	const [isSaving, setIsSaving] = useState(false);
	const [isShowConfirmBuy, setIsShowConfirmBuy] = useState(false);
	const [imageSrc, setImageSrc] = useState(null);
	const [invenItem, setInvenItem] = useState(null);
	const [isShowItem, setIsShowItem] = useState(false);
	const [owner, setOwner] = useState(null);
	const [itemType, setItemType] = useState();
	// const [discordWebHook] = useState('https://discord.com/api/webhooks/1245568924825686089/hwTwnB2WV1qTVDgOJ3u93-RlLZSFyHrkA54KRbYuhb-W7hjFxDvDsow5M3vvFmhZJR3N'); // test
	// const [discordWebHook] = useState('https://discord.com/api/webhooks/1247042228845547571/hyV5oaLyyusmdJNrRnpNlFR9hzV0OqBiNCRyFyhHHl48auIMHVkY7PKf31BhO0d4QyJN');

	useEffect(() => {
		if (storeItem) {
			if (storeItem.count >= 0) {
				fetchInvenItemData(storeItem.invenId).then(item => { setInvenItem(item) }).catch(error => { setInvenItem(null); });
				setItemType('inventory');
			}
			else if (storeItem.count === -1) {
				fetchSkillItemData(storeItem.invenId).then(item => { setInvenItem(item) }).catch(error => { setInvenItem(null); });
				setItemType('skill');
			}
		}
		else {
			setInvenItem(null);
		}
	}, [storeItem]);

	useEffect(() => {
		if (invenItem != null) {
			setImageSrc(`https://submitscore33fafc.blob.core.windows.net/${itemType === 'inventory' ? 'item-icons' : 'skill-icons'}/${storeItem.itemId}.png`);
			fetchCharacterData(storeItem.sellerPlayFabId, storeItem.sellerCharacterId).then(characterData => { setOwner(characterData) }).catch(error => { setOwner(null) });
		}
		else {
			setOwner(null);
		}
	}, [invenItem]);

	const handleClose = () => {
		setIsShowItem(false);
		setIsShowConfirmBuy(false);
	}

	const refreshCreditAmount = () => {
		getUserCredit(playfabId, currentCharacter.characterId).then(value => {
			if (value && value.amount) {
				dispatch({ type: 'SET_CREDIT_AMOUNT', payload: value.amount });
			}
		}).catch(error => {
			console.log(error);
		});
	}

	const handleBuyItem = () => {
		if (!playfabId) {
			return toast.warning("Please log in first!");
		}
		if (creditAmount >= storeItem.price) {
			setIsSaving(true);
			buyItemUsingCredit(storeItem.id, playfabId, currentCharacter.characterId, storeItem.price)
				.then(result => {
					toast("Success!");
					refreshCreditAmount();
					setIsSaving(false);
					setIsShowItem(false);
					updateEvent();
					// const content = (invenItem.property?.itemClass === "equipment" ? { "name": "LEVEL", "value": invenItem.level.toString() } : { "name": "Count", "value": invenItem.remainingUses.toString() });
					// const embed = {
					// 	title: `${invenItem.property?.displayName} Sold!`,
					// 	color: 16753920,
					// 	fields: [
					// 		{
					// 			"name": "Item",
					// 			"value": invenItem.property?.displayName
					// 		},
					// 		{
					// 			"name": "Type",
					// 			"value": invenItem.property?.itemClass
					// 		},
					// 		{
					// 			"name": "Amount",
					// 			"value": storeItem.price + " ZBIT"
					// 		},
					// 		content,
					// 		{
					// 			"name": "Buyer",
					// 			"value": '@' + currentCharacter.characterName
					// 		},
					// 		{
					// 			"name": "Seller",
					// 			"value": '@' + owner.characterName
					// 		}
					// 	],
					// 	thumbnail: {
					// 		url: `https://submitscore33fafc.blob.core.windows.net/item-icons/${invenItem?.itemId}.png`
					// 	},
					// 	footer: {
					// 		text: "Powered by Satoria"
					// 	}
					// };
					// const response = fetch(discordWebHook, {
					// 	method: 'POST',
					// 	headers: {
					// 		'Content-Type': 'application/json'
					// 	},
					// 	body: JSON.stringify({
					// 		embeds: [embed], // Wrap the embed object inside an array since multiple embeds can be sent
					// 	})
					// });
				}).catch(error => {
					toast(error || "Error! Failed to buy item.");
					// console.log(error);
					setIsSaving(false);
					setIsShowItem(false);
					setIsShowConfirmBuy(false);
				});
		}
		else {
			toast("Error! Insufficient credits.");
		}
	}

	return (
		<div className='div-item-card'>
			<div className='div-item-image'>
				{
					imageSrc && (
						<ImageWithFallback
							src={imageSrc}
							fallbackSrc="/images/150.png"
							alt="Item Image"
						/>
					)
				}

			</div>
			<div>
				<span>{invenItem?.property.displayName}</span><br />
				<span style={{ fontSize: '9px' }}>{parseFloat(storeItem.price.toFixed(6))} ZBIT</span>
				<p style={{ fontSize: '9px' }}>Count:&nbsp;{invenItem && invenItem.remainingUses ? invenItem.remainingUses : 1} </p>
			</div>
			<div style={{ position: 'absolute', bottom: '15px', width: '100%' }}>
				{
					invenItem && (
						<button className='button-gradient-1 btn-small' onClick={() => { setIsShowItem(true) }}>VIEW</button>
					)
				}

				<Modal show={isShowItem} onHide={handleClose} backdrop={true} className='modal-cm modal-sm'>
					<Modal.Body>
						<div className='row m-0 p-0' style={{ fontSize: '9px' }}>
							<div className='col-6'>
								{invenItem?.property.itemClass ? invenItem?.property.itemClass.toUpperCase() : invenItem?.property.skillClass.toUpperCase()}
							</div>
							<div className='col-6 text-end'>
								@{owner?.characterName}
							</div>
						</div>
						<div className='pt-3 pb-3'>
							<div className='text-center'>
								<div className='div-item-image mb-4'>
									<Image
										src={imageSrc}
									/>
								</div>
								<h6 className='mb-3'>{invenItem && (invenItem.property.displayName)}</h6>
							</div>
							<ItemProps invenItem={invenItem} itemType={itemType} />
						</div>
						<div className='text-center'>
							<label className='mb-2'>{storeItem.price} ZBIT (fee: {(storeItem.price * 0.05).toFixed(2)}  ZBIT)</label>
							<br />
							{
								storeItem.sellerPlayFabId === playfabId ? (
									<Link to={itemType === 'inventory' ? '/inventory' : '/skill'}>
										{itemType === 'inventory' ? 'Your Inventory' : 'Your Skill'}
									</Link>
								) : (
									<button
										className='button-gradient-1'
										disabled={isSaving}
										title={creditAmount < storeItem.price * 1.03 ? 'Insufficient credits' : ''}
										onClick={() => {
											if (playfabId) {
												if (creditAmount > storeItem.price * 1.05) {
													setIsShowConfirmBuy(true);
													setIsShowItem(false);
												} else {
													toast.error("Insuffient balance!");
												}
											} else {
												toast.warning("Please log in first!");
											}
										}}
									>
										{isSaving ? 'Wait...' : 'BUY NOW'}
									</button>
								)
							}

						</div>
					</Modal.Body>
				</Modal>

				<Modal show={isShowConfirmBuy} onHide={handleClose} backdrop={true} className='modal-cm modal-sm_'>
					<Modal.Body>
						<div className='row m-0 p-0' style={{ fontSize: '9px' }}>
						</div>
						<div className='pt-3 pb-3'>
							<div className='text-center'>
								<div className='div-item-image mb-4'>
									<Image
										src={imageSrc}
									/>
								</div>
								<span>
									Do you want to buy <b className='mb-3'> {invenItem && (invenItem.property.displayName)}</b>?
								</span>
							</div>
						</div>
						<div className='text-center'>
							<label className='mb-2'>{storeItem.price} ZBIT (fee: {(storeItem.price * 0.03).toFixed(2)}  ZBIT)</label>
							<br />
							<button
								className='button-gradient-1 m-3'
								disabled={isSaving || creditAmount < storeItem.price * 1.03}
								title={creditAmount < storeItem.price * 1.03 ? 'Insufficient credits' : ''}
								onClick={handleBuyItem}
							>
								{isSaving ? 'Wait...' : 'CONFIRM'}
							</button>

						</div>
					</Modal.Body>
				</Modal>
			</div>
		</div>
	);
}

export default StoreItemCard;