import React from 'react';
import { useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';

import '../App.css';
import InventoryItem from '../component/InventoryItem';

const PlayerInventories = () => {
	const [invenItems, setInvenItems] = useState([]);
	const [isLoading, setIsLoading] = useState(false);
	const { playfabId, currentCharacter } = useSelector(state => state.login);

	const fetchInvenItems = useCallback(async () => {
		setIsLoading(true);
		try {
			const response = await fetch(`https://submitscore.azurewebsites.net/api/playerinventoryitem?code=GnMmwWSzDumv67-KfI3MCuCw2dN6kC4TrBeZV750_MzmAzFuEqmsAA%3D%3D`,
				{
					method: "POST",
					headers: {
						"Content-Type": "application/json",
					},
					body: JSON.stringify({ playFabID: playfabId, characterID: currentCharacter.characterId, "walletAddr": "" })
				}
			);
			if (!response.ok) throw new Error('Network response was not ok');
			const result = await response.json();
			if (result.isOK) {
				// console.log(result);
				setInvenItems(result.inventory);
			}
			else {
				console.log("empty inventory:");
				setInvenItems([]);
			}
		} catch (error) {
			console.error('Error fetching characters:', error);
			setInvenItems([]);
		}
		setIsLoading(false);
	}, [playfabId, currentCharacter]);

	useEffect(() => {
		if (currentCharacter) {
			fetchInvenItems()
		} else {
			setInvenItems([])
		}
	}, [playfabId, currentCharacter, fetchInvenItems]);

	const refreshItemList = () => {
		setInvenItems([]);
		fetchInvenItems();
	};

	return (
		<>
			{
				isLoading ? (<p className='text-center mt-5'> Loading... </p>) :
					(
						<>
							{
								invenItems.length === 0 ?
									(
										<p className='text-center mt-5'> No inventory items </p>
									)
									:
									(
										<div className='mt-3'>
											{
												invenItems.map(
													(invenItem, index) =>
													(
														<InventoryItem isListed={false} curInvenItem={invenItem} curStoreItem={null} updateEvent={refreshItemList} key={index} />
													)
												)
											}
										</div>
									)
							}
						</>
					)
			}
		</>
	);
};
export default PlayerInventories;
