const GET_MEMPOOL_RECOMMENDED_FEES = "https://submitscore.azurewebsites.net/api/getmempoolrecommendedfees?code=UNEU1KV345Dn4Czu_5NHwNjCJ_5XonyvpjruNozeC9PSAzFuulqMRA%3D%3D";
export const GetRecommendedFees = () => {
    return new Promise(async (resolve, reject) => {
        try {
            const response = await fetch(GET_MEMPOOL_RECOMMENDED_FEES,
                {
                    method: "POST",
                    headers: { "Content-Type": "application/json" },
                }
            );
            if (!response.ok) {
                reject("network error");
            }
            else {
                var result = await response.json();
                // console.log("fetchzBitDataOfWallet:", result);
                if (result.isOK === true) {
                    resolve(JSON.parse(result.data))
                }
                else {
                    reject(result.messageValue);
                }
            }
        } catch (error) {
            console.error('Error fetching stores:', error);
            reject(error);
        }
    });
};
