import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { useSelector } from 'react-redux';

import './App.css';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import MyGames from './pages/MyGames'
import Marketplace from './pages/MarketPlace';
import MarketPlaceORD from './pages/MarketPlaceORD';
import TradeHistory from './pages/TradeHistory';
import Inventory from './pages/Inventory';
import Skill from './pages/Skill';
import Login from './pages/Login';
// import Register from './pages/Register';
import Refill from './pages/Refill';
import Shop from './pages/Shop';
import ZbitLoot from './pages/ZbitLoot';
import Profile from './pages/Profile';
import Withdraw from './pages/Withdraw';
import History from './pages/History';
import { useDispatch } from 'react-redux';

function App() {
  const dispatch = useDispatch();
  useEffect(
    () => {
      dispatch({ type: 'SET_LOGIN_PLAYFAB_ID', payload: null });
      dispatch({ type: 'SET_CURRENT_CHARACTER', payload: "" });
    }, []);
  const { playfabId } = useSelector(state => state.login);

  return (<React.Fragment>
    <Router>
      <Routes>
        <Route path="/" element={<MyGames />} />
        <Route path="/login" element={<Login />} />
        {/* <Route path="/marketplace-ord" element={<MarketPlaceORD />} /> */}
        <Route path="/marketplace" element={<Marketplace />} />
        <Route path="/trade" element={<TradeHistory />} />
        <Route path="/inventory" element={<Inventory />} />
        <Route path="/skill" element={<Skill />} />
        <Route path="/shop" element={<Shop />} />
        <Route path="/zbitloot" element={<ZbitLoot />} />
        <Route path="/refill" element={<Refill />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="/withdraw" element={<Withdraw />} />
        <Route path="/history" element={<History />} />
      </Routes>
    </Router>
    <ToastContainer
      position="top-right"
      autoClose={5000}
      hideProgressBar={false}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
      theme="dark"
    />
  </React.Fragment>)
  // if (!playfabId) {
  //   return (<React.Fragment>
  //     <Router>
  //       <Routes>
  //         <Route path="/" element={<Login />} />
  //         {/* <Route path="/register" element={<Register />} /> */}
  //         <Route path="/*" element={<Login />} />
  //       </Routes>
  //     </Router>
  //     <ToastContainer
  //       position="top-right"
  //       autoClose={5000}
  //       hideProgressBar={false}
  //       newestOnTop={false}
  //       closeOnClick
  //       rtl={false}
  //       pauseOnFocusLoss
  //       draggable
  //       pauseOnHover
  //       theme="dark"
  //     />
  //   </React.Fragment>)
  // }
  // else {
  //   return (
  //     <React.Fragment>
  //       <Router>
  //         <Routes>
  //           <Route path="/" element={<MyGames />} />
  //           <Route path="/marketplace" element={<Marketplace />} />
  //           <Route path="/trade" element={<TradeHistory />} />
  //           <Route path="/inventory" element={<Inventory />} />
  //           <Route path="/skill" element={<Skill />} />
  //           <Route path="/shop" element={<Shop />} />
  //           <Route path="/zbitloot" element={<ZbitLoot />} />
  //           <Route path="/refill" element={<Refill />} />
  //           <Route path="/profile" element={<Profile />} />
  //           <Route path="/withdraw" element={<Withdraw />} />
  //           <Route path="/history" element={<History />} />
  //         </Routes>
  //       </Router>
  //       <ToastContainer
  //         position="top-right"
  //         autoClose={5000}
  //         hideProgressBar={false}
  //         newestOnTop={false}
  //         closeOnClick
  //         rtl={false}
  //         pauseOnFocusLoss
  //         draggable
  //         pauseOnHover
  //         theme="dark"
  //       />
  //     </React.Fragment>
  //   );
  // }

}

export default App;