import React, { useState } from 'react';
import '../App.css';
import MarketPlaceORDUI from '../component/MarketPlaceORDUI';
import PageTitle from '../component/PageTitle';
import SearchBar from '../component/SearchBar';
import SideMenu from '../component/SideMenu';
import Header from '../component/Header';
import CharacterSelector from '../component/CharacterSelector';
function MarketPlaceORD() {

  const [zbitWalletInfo] = useState(null);
  const [tab, setTab] = useState('');
  const updateSearchValue = (searchValue) => { };

  return (
    <div className="App">
      {/* Side Menu */}
      <SideMenu zbitWalletInfo={zbitWalletInfo} /> {/* Pass zbit wallet info as a prop to SideMenu */}
      {/* Main Content */}
      <div className="main-content">
        {/* Header */}
        <Header />
        <div className="row m-0 p-0 justify-content-between">
          <div className="col-sm-6 col-md-8">
            <SearchBar updateSearchValue={updateSearchValue} />
          </div>
          <div className="col-sm-3 text-end p-0">
            <CharacterSelector type={'wallet'} />
          </div>
        </div>
        <PageTitle />
        {/* MarketplaceORD Content */}
        <button className={`btn-tab me-2 ${tab === "" ? "active" : ""}`} onClick={() => { setTab('') }}>Every Ordinal Items</button>
        <button className={`btn-tab me-2 ${tab === "wallet" ? "active" : ""}`} onClick={() => { setTab('wallet') }}>My Ordinal Items</button>
        {
          tab === 'wallet' ? (
            <MarketPlaceORDUI type="wallet" />
          ) : (
            <MarketPlaceORDUI type="" />
          )
        }
      </div>
    </div >
  );
}

export default MarketPlaceORD;
