import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useState, useEffect } from 'react';
import ReactPaginate from 'react-paginate';

import ShopItemCard from './ShopItemCard';
import { fetchShopItems } from '../Api/marketplace_api';
import PageTitle from './PageTitle';
import InvenCategoryTab from './InvenCategoryTab';
import SearchBar from './SearchBar';
import CharacterSelector from './CharacterSelector';
import '../assets/css/style.css';

const ShopPanel = () => {
	const [storeItems, setStoreItems] = useState([]);
	const [totalCount, setTotalCount] = useState(0);
	const [pageSize] = useState(24);
	const [pageNum, setPageNum] = useState(0);
	const [searchValue, setSearchValue] = useState('');
	const [activeTabs, setActiveTabs] = useState(['all']);
	const [isLoading, setIsLoading] = useState(false);

	const refreshCurrentPage = () => {
		setIsLoading(true);
		setStoreItems([]);
		let searchKey = "";
		if (!activeTabs.includes('all')) {
			searchKey = activeTabs.join('|');
		}
		fetchShopItems(pageNum, pageSize, searchKey, searchValue).then((result) => {
			setStoreItems(result.stores);
			setTotalCount(result.totalCount);
			setIsLoading(false);
		})
			.catch(error => {
				console.log("error in fetchStoreItems:", error);
				setIsLoading(false);
			});
	}

	useEffect(() => {
		refreshCurrentPage();
	}, [pageNum, activeTabs, searchValue]);

	const handlePageClick = (event) => {
		setStoreItems([]);
		setPageNum(event.selected);
	};

	const handleUpdateTabs = (tabs) => {
		setTotalCount(0);
		setPageNum(0);
		setActiveTabs(tabs);
	};

	const updateSearchValue = (searchValue) => {
		setSearchValue(searchValue);
	};

	return (
		<>
			<div className="row m-0 p-0">
				<div className="col-sm-6 col-md-8">
					<SearchBar updateSearchValue={updateSearchValue} />
				</div>
				<div className="col-sm-6 col-md-4 text-end p-0">
					<CharacterSelector />
				</div>
			</div>

			<PageTitle />
			<InvenCategoryTab selectedTabs={activeTabs} handleUpdateTabs={handleUpdateTabs} />
			<div className='pagination-div mt-3 ms-2' >
				<ReactPaginate
					breakLabel="..."
					nextLabel="next >"
					onPageChange={handlePageClick}
					pageRangeDisplayed={5}
					pageCount={parseInt((totalCount - 1) / pageSize) + 1}
					previousLabel="< prev"
					renderOnZeroPageCount={null}
				/>
			</div>

			<div>
				{isLoading ?
					(
						<div className='text-center mt-5'> Loading Items ...</div>
					) :
					(
						storeItems.length === 0 ?
							(
								<div className='text-center mt-5'> No Item </div>
							)
							:
							(
								storeItems.map((storeItem, index) =>
								(
									<ShopItemCard
										shopItem={storeItem}
										key={index}
										updateEvent={refreshCurrentPage}
									/>
								)
								)
							)
					)
				}
			</div>
		</>
	);

}

export default ShopPanel;