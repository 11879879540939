import { useSelector } from 'react-redux';

const OnigiriBox = () => {
    const {
			onigiriAmount,
    } = useSelector(state => state.wallet);

    return (
        <div className='zbit-container col m-0 text-center'>
            <div className=''>
                <img src={`/images/onigiri.png`} style={{ width: '30px' }} alt="Zbit Logo" />           
                <div className='d-inline-block'>
                    <span>{onigiriAmount}</span>
                    <span className="zbit-text">ONIGIRI</span>
                </div>
            </div>
        </div>
    );
}

export default OnigiriBox;