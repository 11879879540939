const GET_ZBIT_DATA_API = "https://submitscore.azurewebsites.net/api/getzbitdataofwallet?code=kcJH6bw3bp2w0PJFjfAvFF9QgKx5D1B2kSkYjC8hJu0aAzFuBxbT5A%3D%3D";
const CREAT_INSCRIPTION_ORDER_API = "https://submitscore.azurewebsites.net/api/createinscriptionorder?code=Uu7xofMbhAke4RJxcJspqYOfAiwd8Qq3Mw4pon28hq9xAzFutZUbWg%3D%3D";
const SEARCH_INSCRIPTION_ORDER_API = "https://submitscore.azurewebsites.net/api/searchinscriptionorder?code=OzVrdoCALtB1HZdDJXAW9INxgZd-Pz2DZ3SUSznSFuPMAzFuCYN1Dw%3D%3D";
const GET_INSCRIPTION_DATA_API = "https://submitscore.azurewebsites.net/api/getinscriptiondata?code=eulO91hXt5TMr79SgTOYf0gmbDFzpxS2p9UALWy65nS0AzFuiJVvMA%3D%3D";

export const DEV_ADDRESS_ZBIT = "bc1pyjs2wghtx8ek765fxdgnuv8kffh6t7xdlyaql835a2jatphd8p8srn4u6l";

export const fetchzBitDataOfWallet = async (ordinalsAccount) => {
    return new Promise(async (resolve, reject) => {
        try {
            const response = await fetch(
                GET_ZBIT_DATA_API,
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({ walletAddr: ordinalsAccount })
                }
            );
            if (!response.ok) {
                reject("network error");
            }
            else {
                var result = await response.json();
                // console.log("fetchzBitDataOfWallet:", result);
                if (result.isOK === true) {
                    const data = result.data;
                    if (data.code === 0) {
                        resolve(data.data);
                    }
                    else {
                        reject(data.msg);
                    }
                }
                else{
                    reject(result.messageValue);
                }
                
            }

        } catch (error) {
            console.error('Error fetching stores:', error);
            reject(error);
        }
    });
}

export const CreateInscriptionOrder = async (recipient, amount, feeRate) => {
    return new Promise(async (resolve, reject) => {
        try {
            const response = await fetch(
                CREAT_INSCRIPTION_ORDER_API,
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({ recipient: recipient, feeRate: feeRate, amount: amount })
                }
            );
            if (!response.ok) {
                reject("network error");
            }
            else {
                var result = await response.json();
                console.log("CreateInscriptionOrder:", result);
                if (result.isOK === true) {
                    const data = result.data;
                    if (data.code === 0) {
                        resolve(data.data);
                    }
                    else {
                        reject(data.msg);
                    }
                }
                else{
                    reject(result.messageValue);
                }
                
            }

        } catch (error) {
            console.error('Error fetching stores:', error);
            reject(error);
        }
    });
    
}

export const SearchInscriptionOrder = async (orderId) => {
    return new Promise(async (resolve, reject) => {
        try {
            const response = await fetch(
                SEARCH_INSCRIPTION_ORDER_API,
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({ orderId: orderId })
                }
            );
            if (!response.ok) {
                reject("network error");
            }
            else {
                var result = await response.json();
                console.log("SearchInscriptionOrder:", result);
                if (result.isOK === true) {
                    const data = result.data;
                    if (data.code === 0) {
                        resolve(data.data);
                    }
                    else {
                        reject(data.msg);
                    }
                }
                else{
                    reject(result.messageValue);
                }
                
            }

        } catch (error) {
            console.error('Error SearchInscriptionOrder:', error);
            reject(error);
        }
    });
}

export const GetInscriptionData = async (incriptionId) => {
    return new Promise(async (resolve, reject) => {
        try {
            const response = await fetch(
                GET_INSCRIPTION_DATA_API,
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({ incriptionId: incriptionId })
                }
            );
            if (!response.ok) {
                reject("network error");
            }
            else {
                var result = await response.json();
                console.log("GetInscriptionData:", result);
                if (result.isOK === true) {
                    const data = result.data;
                    if (data.code === 0) {
                        resolve(data.data);
                    }
                    else {
                        reject(data.msg);
                    }
                }
                else{
                    reject(result.messageValue);
                }
                
            }

        } catch (error) {
            console.error('Error GetInscriptionData:', error);
            reject(error);
        }
    });

}