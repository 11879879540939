import { Modal, Button } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';


const PlaceOrderModal = ({ show, handleModalClose, setMempoolFee, handlePayWithWallet, feeData, mempoolFee, curAciveOrder }) => {
    return (
        <Modal
            show={show}
            onHide={handleModalClose}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            backdrop={true}
            keyboard={false}
            className='modal-cm'
        >
            <Modal.Header closeButton>
                <Modal.Title>Inscribing Order</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Container>
                    <Row className="mb-5">
                        {
                            feeData && (
                                <>
                                    <Col><Form.Check label={`fastestFee: ${feeData.fastestFee} sat/vb`} name="fee_check" type="radio" id={`fastestFee`} onChange={() => { setMempoolFee(feeData.fastestFee) }} checked={mempoolFee === feeData.fastestFee} /></Col>
                                    <Col><Form.Check label={`halfHourFee: ${feeData.halfHourFee} sat/vb`} name="fee_check" type="radio" id={`halfHourFee`} onChange={() => { setMempoolFee(feeData.halfHourFee) }} checked={mempoolFee === feeData.halfHourFee} /></Col>
                                    <Col><Form.Check label={`hourFee: ${feeData.hourFee} sat/vb`} name="fee_check" type="radio" id={`hourFee`} onChange={() => { setMempoolFee(feeData.hourFee) }} checked={mempoolFee === feeData.hourFee} /></Col>
                                    <Col><Form.Check label={`economyFee: ${feeData.economyFee} sat/vb`} name="fee_check" type="radio" id={`economyFee`} onChange={() => { setMempoolFee(feeData.economyFee) }} checked={mempoolFee === feeData.economyFee} /></Col>
                                    <Col><Form.Check label={`minimumFee: ${feeData.minimumFee} sat/vb`} name="fee_check" type="radio" id={`minimumFee`} onChange={() => { setMempoolFee(feeData.minimumFee) }} checked={mempoolFee === feeData.minimumFee} /></Col>
                                </>
                            )
                        }
                    </Row>
                    <Row>
                        {
                            curAciveOrder && (
                                <>
                                    <h6>OrderId:  {curAciveOrder.orderId}</h6>
                                    <h6>Miner Fee:  {curAciveOrder.minerFee}</h6>
                                    <h6>Service Fee:  {curAciveOrder.serviceFee}</h6>
                                    <h6>Developer Fee:  {curAciveOrder.devFee}</h6>
                                    <h6>Output Value:  {curAciveOrder.outputValue}</h6>
                                    <h6>Totla:  {curAciveOrder.devFee + curAciveOrder.minerFee + curAciveOrder.serviceFee + curAciveOrder.outputValue * 2}</h6>
                                </>
                            )
                        }
                    </Row>
                </Container>

            </Modal.Body>
            <Modal.Footer>
                <button className='button-gradient-1' onClick={handlePayWithWallet}>
                    Pay with Wallet
                </button>
            </Modal.Footer>
        </Modal>
    );
}

export default PlaceOrderModal;


