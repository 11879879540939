import React, { useState } from 'react';
import '../App.css';
import SideMenu from '../component/SideMenu';
import Header from '../component/Header';
import GameCard from '../component/GameCard';
import SearchBar from '../component/SearchBar';
// import CharacterSelector from '../component/CharacterSelector';

function MyGames() {
	const [zbitWalletInfo] = useState(null); // Initialize zbit wallet info

	const updateSearchValue = (searchValue) => {
	};

	return (
		<div className="App">
			{/* Side Menu */}
			<SideMenu zbitWalletInfo={zbitWalletInfo} /> {/* Pass zbit wallet info as a prop to SideMenu */}
			{/* Main Content */}
			<div className="main-content">
				<Header />
				<div className="row m-0 p-0">
					<div className="col-sm-6 col-md-8">
						<SearchBar updateSearchValue={updateSearchValue} />
					</div>
					<div className="col-sm-6 col-md-4 text-end p-0">
						{/* <CharacterSelector /> */}
					</div>
				</div>
				<div className='mt-4 p-3'>
					<GameCard />
				</div>
			</div>
		</div>
	);
}

export default MyGames;
