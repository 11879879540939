import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAddress, request, RpcErrorCode } from 'sats-connect';
import { toast } from 'react-toastify';

function XverseWalletConnect({ style, type = 'Mainnet', isSign = true }) {
	const dispatch = useDispatch();
	const { xverseOrdinalsAddr, xversePaymentsAddr } = useSelector(state => state.wallet);
	const ConnectXVerseWallet = async () => {
		const getAddressOptions = {
			payload: {
				purposes: ['ordinals', 'payment'],
				message: 'Address for receiving Ordinals',
				network: {
					type: type
				},
			},
			onFinish: (response) => {
				if (response.addresses.length > 1) {
					const msg = new Date().getTime().toString();
					// const msg = "1725985936493";
					console.log(response);
					dispatch({ type: 'SET_XVERSE_SIGNATURE_MESSAGE', payload: msg });
					response.addresses.forEach(async (walletAddr) => {
						if (walletAddr.purpose === "ordinals") {
							if (isSign) await GetSignature(walletAddr.address, "SET_XVERSE_ORDINALS_SIGNATURE", msg);
							dispatch({ type: 'SET_XVERSE_ORDINALS_ADDRESS', payload: walletAddr.address });
							dispatch({ type: 'SET_XVERSE_ORDINALS_PUBKEY', payload: walletAddr.publicKey });
						}
						else if (walletAddr.purpose === 'payment') {
							if (isSign) await GetSignature(walletAddr.address, "SET_XVERSE_PAYMENT_SIGNATURE", msg);
							dispatch({ type: 'SET_XVERSE_PAYMENT_ADDRESS', payload: walletAddr.address });
							dispatch({ type: 'SET_XVERSE_PAYMENT_PUBKEY', payload: walletAddr.publicKey });
						}
					});
				}
			},
			onCancel: () => alert('Request canceled'),
		}
		await getAddress(getAddressOptions);
	}

	const GetSignature = async (address, type, msg) => {
		try {
			const response = await request("signMessage", { address: address, message: msg, protocol: "BIP322" });
			// console.log(response);
			if (response.status === "success") {
				dispatch({ type: type, payload: response?.result?.signature });
			} else {
				if (response.error.code === RpcErrorCode.USER_REJECTION) {
					toast.error("Rejected to connect.");
				} else {
					toast.error("Get error in signing wallet.");
				}
			}
		} catch (err) {
			toast.error("Failed to sign wallet.");
		}
	}

	return (
		<>
			{
				(xverseOrdinalsAddr && xversePaymentsAddr) ? (
					<button className='button-gradient-2' style={style}>Connected to Xverse { /* xverseOrdinalsAddr.substr(0,5) + '...' + xverseOrdinalsAddr.substr(-5) */}</button>
				) : (
					<button className='button-gradient-1' onClick={() => { ConnectXVerseWallet() }} style={style}>Connect to Xverse Wallet</button>
				)
			}
		</>
	);
}

export default XverseWalletConnect;