import UpgradeSlot from './upgradeslot';

const ItemProps = ({ invenItem, itemType }) => {
	if (itemType === 'inventory') {
		if (invenItem.property.itemClass === "equipment") {
			if (invenItem.property.property != null) {
				return (
					<div className='row m-0 p-0' style={{ fontSize: '10px' }}>
						<div className='col-sm-6 p-0'>
							<div className='p-3'>
								<p>LEVEL: {invenItem.level}</p>
								<p>DMG: {(invenItem.property.property.damage).toFixed(2)}</p>
								<p>FORCE: {(invenItem.property.property.force * invenItem.force_amp).toFixed(2)}</p>
								<p>QUANTUM: {(invenItem.property.property.quantum * invenItem.magic_amp).toFixed(2)}</p>
								<p>LUK: {(invenItem.property.property.luck * invenItem.luck_amp).toFixed(2)}</p>
								<p>DEX: {(invenItem.property.property.dexterity * invenItem.dexterity_amp).toFixed(2)}</p>
								<p>HP: {(invenItem.property.property.maxHealth * invenItem.health_amp).toFixed(2)}</p>
								<p>MP: {(invenItem.property.property.maxMana * invenItem.mana_amp).toFixed(2)}</p>
								<p>DEF: {invenItem.property.property.defense.toFixed(2)}</p>
							</div>
						</div>
						<div className='col-sm-6 p-0 pt-3'>
							<UpgradeSlot invenItem={invenItem} />
						</div>
					</div>
				);
			}
		}
		else if (invenItem.property.itemClass === "use") {
			return (
				<div className='p-3'>
					<p>Count: {(invenItem.remainingUses)}</p>
					<p>Effect Value: {invenItem.property.property.effectPower}</p>
				</div>
			);
		}
		else if (["stuff", "farm"].includes(invenItem.property.itemClass)) {
			return (
				<div className='p-3'>
					<p>Count: {(invenItem.remainingUses)}</p>
				</div>
			);
		}
	}
	else if (itemType === 'skill') {
		return (
			<div className='p-3'>
				<p>Level: {(invenItem.level)}</p>
			</div>
		)
	}
}

export default ItemProps;