import React, { useState } from 'react';
import '../App.css';
import MarketPlaceUI from '../component/MarketPlaceUI';
import SideMenu from '../component/SideMenu';
import Header from '../component/Header';

function MarketPlace() {
  const [zbitWalletInfo] = useState(null); // Initialize zbit wallet info

  return (
    <div className="App">
      {/* Side Menu */}
      <SideMenu zbitWalletInfo={zbitWalletInfo} /> {/* Pass zbit wallet info as a prop to SideMenu */}
      {/* Main Content */}
      <div className="main-content">
        {/* Header */}
        <Header />    
        {/* Marketplace Content */}
        <MarketPlaceUI />
      </div>
    </div>
  );
}

export default MarketPlace;
