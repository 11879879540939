import { Image } from 'react-bootstrap';

const GameCard = () => {
    return (
        <div className='d-inline-block'>
            <div>
                <Image
                    src={`/images/games/satoria.jpeg`}
                    width={"400px"}
                />
            </div>
            <div className='mt-3'>
                <h6>SATORIA</h6>
                <span>PLAYTIME: 3 DAYS - 12 HOURS - 54 MINUTES</span>
            </div>
        </div>
    );
}

export default GameCard;