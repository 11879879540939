import React from 'react';
import { useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';

import '../App.css';
import InventoryItem from '../component/InventoryItem';
import SkillItem from '../component/SkillItem';

const PlayerListedInventories = ({ type }) => {
	const [listedInvenItems, setListedInvenItems] = useState([]);
	const [isLoading, setIsLoading] = useState(false);
	const { playfabId, currentCharacter } = useSelector(state => state.login);

	const fetchListedInvenItems = useCallback(async () => {
		setIsLoading(true);
		try {
			const response = await fetch(`https://submitscore.azurewebsites.net/api/loadplayerstoreitems?code=H9EG4B0UArDu2ip9ccK80s0UaRghPXSgBfM8yYzjr-NbAzFuSv-L-g%3D%3D`,
				{
					method: "POST",
					headers: {
						"Content-Type": "application/json",
					},
					body: JSON.stringify({ playFabID: playfabId, characterID: currentCharacter.characterId, "walletAddr": "" })
				}
			);
			if (!response.ok) throw new Error('Network response was not ok');
			const result = await response.json();
			if (result.isOK) {
				let filteredItems = null;
				if (type === 'inventory') {
					filteredItems = result.stores.filter(
						(item) => item.done === false && item.count >= 0
					);
				}
				else if (type === 'skill') {
					filteredItems = result.stores.filter(
						(item) => item.done === false && item.count === -1
					);
				}
				setListedInvenItems(filteredItems);
			}
			else {
				console.log("empty inventory:");
				setListedInvenItems([]);
			}
		} catch (error) {
			console.error('Error fetching characters:', error);
			setListedInvenItems([]);
		}
		setIsLoading(false);
	}, [playfabId, currentCharacter]);

	useEffect(() => {
		if (currentCharacter) {
			fetchListedInvenItems()
		} else {
			setListedInvenItems([])
		}
	}, [playfabId, currentCharacter, fetchListedInvenItems]);

	const refreshItemList = () => {
		setListedInvenItems([]);
		fetchListedInvenItems();
	};

	return (
		<>
			{
				isLoading ? (<p className='text-center mt-5'> Loading... </p>) :
					(
						<>
							{
								listedInvenItems.length === 0 ?
									(
										<p className='text-center mt-5'> No store items </p>
									)
									:
									(
										<div className='mt-3'>
											{
												listedInvenItems.map((storeItem, index) => {
													if (storeItem.count >= 0) {
														return (
															<InventoryItem
																isListed={true}
																curInvenItem={null}
																curStoreItem={storeItem}
																updateEvent={refreshItemList}
																key={index}
															/>
														);
													} else if (storeItem.count === -1) {
														return (
															<SkillItem
																isListed={true}
																curSkillItem={null}
																curStoreItem={storeItem}
																updateEvent={refreshItemList}
																key={index}
															/>
														);
													}
													return null;
												})
											}
										</div>
									)
							}
						</>
					)
			}
		</>
	);
};
export default PlayerListedInventories;
