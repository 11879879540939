import { Modal, Button } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import { useState, useEffect } from 'react';

const PayTokenModal = ({show, handlePayTokenWithWallet, handleModalClose}) => {
    const PayTokenButton = ()=>{
        handlePayTokenWithWallet();
    };
    return (
        <Modal show={show} onHide={handleModalClose} size="lg" aria-labelledby="contained-modal-title-vcenter" backdrop="static" keyboard={false}>
            <Modal.Header closeButton>
                <Modal.Title>Pay Token </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                    <Form>
                        <Form.Label>You will pay zbit token to seller and developer.</Form.Label>
                    </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" onClick={PayTokenButton}>
                    Pay with Wallet
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

export default PayTokenModal;