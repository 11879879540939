import React, { useState, useEffect } from 'react';
import '../assets/css/SearchBar.css';
import { useDispatch, useSelector } from 'react-redux';

const Header = () => {  
  return (
    <></>
  );
};
export default Header;
