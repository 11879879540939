import { useState, useEffect } from 'react';
import Card from 'react-bootstrap/Card';
import { Modal } from 'react-bootstrap';
import { toast } from 'react-toastify';

import { fetchSkillItemData } from '../Api/marketplace_api';
import { useSelector } from 'react-redux';
import '../assets/css/style.css'
import ItemProps from './itemprops';
import ImageWithFallback from './ImageWithFallback';

const SkillItem = ({ isListed, curSkillItem, curStoreItem, updateEvent }) => {
	// console.log("SkillItem:", curSkillItem);
	const { walletAddr } = useSelector(state => state.wallet);
	const { playfabId, currentCharacter } = useSelector(state => state.login);
	const [skillItem, setSkillItem] = useState(curSkillItem);
	const [isShowAddModal, setIsShowAddModal] = useState(false);
	const [isSaving, setIsSaving] = useState(false);
	const [listPrice, setListPrice] = useState(curStoreItem ? curStoreItem.price : 0);
	const [imageSrc, setImageSrc] = useState();
	const [isShowCancelModal, setIsShowCancelModal] = useState(false);
	const [isShowItem, setIsShowItem] = useState(false);

	const handleClickList = () => {
		setIsShowAddModal(true);
	}

	const handleClickCancel = () => {
		setIsShowCancelModal(true);
	}

	const handleItemClose = () => {
		setIsShowItem(false);
	}

	const handleClose = () => {
		setIsShowAddModal(false);
		setIsShowCancelModal(false);
		setIsShowItem(false);
	}

	const handleListItem = async () => {
		if (listPrice > 0) {
			setIsSaving(true);
			try {
				const response = await fetch(`https://submitscore.azurewebsites.net/api/registerskilltomarketplace?code=mLSlY0IMPMTEHc4uwuWQs3XF8zm3S6bvIebYEsFjCGFxAzFuthtpSw%3D%3D`,
					{
						method: "POST",
						headers: {
							"Content-Type": "application/json",
						},
						body: JSON.stringify({
							playFabID: playfabId,
							characterID: currentCharacter.characterId,
							skillInstanceId: skillItem.id,
							sellerAddress: walletAddr,
							price: listPrice
						})
					}
				);
				if (!response.ok) throw new Error('Network response was not ok');
				const result = await response.json();
				if (result.isOK) {
					console.log(result);
					toast('Success');
					setIsShowAddModal(false);
					updateEvent();
				}
				else {
					console.log(result);
					toast('Error: ' + result.messageValue);
				}
				setIsSaving(false);
			} catch (error) {
				console.error('Error fetching characters:', error);
				setIsSaving(false);
			}
		}
		else {
			toast('Error: Invalid Price');
		}
	}

	const handleUpdateStore = async () => {
		if (listPrice > 0) {
			setIsSaving(true);
			try {
				const response = await fetch(`https://submitscore.azurewebsites.net/api/editstoreitem?code=lIwo6QK4XHlvYe0t7o3fl4_5LicIiqeut5_C3kiQoUwuAzFuZl8Ypg%3D%3D`,
					{
						method: "POST",
						headers: {
							"Content-Type": "application/json",
						},
						body: JSON.stringify({
							playFabID: playfabId,
							characterID: currentCharacter.characterId,
							storeItemId: curStoreItem.id,
							sellerAddress: walletAddr,
							price: listPrice
						})
					}
				);
				if (!response.ok) throw new Error('Network response was not ok');
				const result = await response.json();
				if (result.isOK) {
					console.log(result);
					toast('Success');
					setIsShowAddModal(false);
					updateEvent();
				}
				else {
					console.log(result);
					toast('Error: ' + result.messageValue);
				}
				setIsSaving(false);
			} catch (error) {
				console.error('Error fetching characters:', error);
				setIsSaving(false);
			}
		}
		else {
			toast('Error: Invalid Price');
		}
	}

	const handleCancelList = async () => {
		setIsSaving(true);
		try {
			const response = await fetch(`https://submitscore.azurewebsites.net/api/cancelliststoreitem?code=N3nR98gsQFeUieFhpoMTG1c6yIZAd2ImfH3dolWwL3wGAzFufMuu6A%3D%3D`,
				{
					method: "POST",
					headers: {
						"Content-Type": "application/json",
					},
					body: JSON.stringify({
						storeItemId: curStoreItem.id,
					})
				}
			);
			if (!response.ok) throw new Error('Network response was not ok');
			const result = await response.json();
			if (result.isOK) {
				console.log(result);
				toast('Success');
				setIsShowCancelModal(false);
				updateEvent();
			}
			else {
				console.log(result);
				toast('Error: ' + result.messageValue);
			}
			setIsSaving(false);
		} catch (error) {
			console.error('Error cancel list:', error);
			setIsSaving(false);
		}
	}

	useEffect(() => {
		if (curStoreItem != null) {
			fetchSkillItemData(curStoreItem.invenId)
				.then(item => {
					setSkillItem(item);
				})
				.catch(error => {
					setSkillItem(null);
				});
		}
	}, [curStoreItem]);

	useEffect(() => {
		setImageSrc(`https://submitscore33fafc.blob.core.windows.net/skill-icons/${skillItem?.skillId}.png`);
	}, [skillItem]);

	return (
		<div className='div-item-card'>
			{
				skillItem ? (
					<>
						<div className='div-item-image'>
							<ImageWithFallback
								src={`https://submitscore33fafc.blob.core.windows.net/skill-icons/${skillItem?.skillId}.png`}
								fallbackSrc="/images/150.png"
								alt="Item Image"
							/>
						</div>
						<div>
							<span>{skillItem?.property.displayName}</span><br />
						</div>
						<div>
							<span>Count: {skillItem?.remainingUses ? skillItem.remainingUses : 1}</span><br />
						</div>
						<div style={{ position: 'absolute', bottom: '20px', width: '100%' }}>
							<button className='button-gradient-1 btn-small' onClick={() => { setIsShowItem(true) }}>VIEW</button>

							<Modal show={isShowItem} onHide={handleItemClose} backdrop={true} className='modal-cm modal-sm'>
								<Modal.Body>
									<div className='row m-0 p-0' style={{ fontSize: '9px' }}>
										<div className='col-6'>
											{skillItem?.property.skillClass.toUpperCase()}
										</div>
										<div className='col-6 text-end'>
											@{currentCharacter.characterName}
										</div>
									</div>
									<div className='pt-3 pb-3'>
										<div className='text-center'>
											<div className='div-item-image mb-4'>
												<ImageWithFallback
													src={imageSrc}
													fallbackSrc="/images/150.png"
													alt="Item Image"
												/>
											</div>
											<h6 className='mb-3'>{skillItem && (skillItem.property.displayName)}</h6>
										</div>
										<ItemProps invenItem={skillItem}  itemType={'skill'}/>
									</div>
									<div className='text-center'>
										{
											!isListed ?
												(skillItem.isNFT ? (
													<div>
														<h6 className='mb-3'>This is Ordinals item.</h6>
													</div>
												) : (
													<div>
														<button className={'button-gradient-1'} onClick={handleClickList}>
															LIST ITEM
														</button>
													</div>
												))


												: (
													<div>
														<label className='mb-2'>{curStoreItem?.price} ZBIT</label>
														<br />
														<button className={'button-gradient-2 mb-2'} onClick={handleClickList} style={{ width: '200px' }}>
															EDIT LISTING
														</button>
														<button className={'button-gradient-2'} onClick={handleClickCancel} style={{ width: '200px' }}>
															CANCEL LISTING
														</button>
													</div>
												)
										}

									</div>
								</Modal.Body>
							</Modal>
						</div>
					</>
				) : (
					<Card className='store-item-card'>
						<Card.Body className='p-5'>
							Loading Item Information...
						</Card.Body>
					</Card>
				)
			}

			<Modal show={isShowAddModal} onHide={handleClose} backdrop={true} className='modal-cm'>
				<Modal.Header closeButton>
					<Modal.Title>{!isListed ? 'List Item' : 'Edit Listing'}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<div className='pt-3 pb-3'>
						<h6 className='mb-3'>{skillItem && (skillItem.property.displayName)}</h6>
						<label className='mb-2'>Price (ZBIT):</label>
						<input
							type="number"
							className="common-input-1 d-block"
							style={{ width: '100%' }}
							placeholder="Price"
							value={listPrice}
							onChange={(e) => { setListPrice(e.target.value) }}
						/>
						<p className='text-secondary'>
							The fee(5%) will be applied.
						</p>
					</div>
				</Modal.Body>
				<Modal.Footer>
					{!isListed ? (
						<button className='button-gradient-1' disabled={isSaving} onClick={handleListItem}>
							{isSaving ? 'Wait...' : 'LIST'}
						</button>
					) : (
						<button className='button-gradient-1' disabled={isSaving} onClick={handleUpdateStore}>
							{isSaving ? 'Wait...' : 'UPDATE'}
						</button>
					)}

				</Modal.Footer>
			</Modal>

			<Modal show={isShowCancelModal} onHide={handleClose} backdrop={true} className='modal-cm'>
				<Modal.Header closeButton>
					<Modal.Title>Cancel</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<div className='pt-3 pb-3'>
						<p className=''>
							Do you want to cancel <b>{skillItem && (skillItem.property.displayName)}</b>?
						</p>
					</div>
				</Modal.Body>
				<Modal.Footer>
					<button className='button-gradient-2' disabled={isSaving} onClick={handleCancelList}>
						{isSaving ? 'Wait...' : 'YES'}
					</button>
				</Modal.Footer>
			</Modal>
		</div>
	);
}

export default SkillItem;