import '../assets/css/style.css';
import TradeReady from './TradeReady';

const PageTitle = () => {
  return (
    <div className='row m-0 p-0'>
      <div className='col-sm-6'>
        <div className="subtitle">
          <h4>CURRENT MARKETPLACE</h4>
        </div>
        <div className="search-bar-title">
          <h2>SATORIA</h2>
        </div>
      </div>
      <div className='col-sm-6 text-end pe-5 pt-5'>
        {/* <TradeReady /> */}
      </div>
    </div>
  );
};
export default PageTitle;
