import React, { useState, useEffect } from 'react';
import ImageWithFallback from './ImageWithFallback';

const HistoryItem = ({ historyItem }) => {
	// console.log(historyItem?.reason);
	const [imageSrc, setImageSrc] = useState(null);

	useEffect(() => {
		try {
			setImageSrc(`https://submitscore33fafc.blob.core.windows.net/item-icons/${historyItem.itemId}.png`);
		}
		catch {
			setImageSrc(`/images/150.png`);
		}
	}, [historyItem]);

	const formatDateTime = (input) => {
		const date = new Date(input);
		const hours = date.getHours();
		const minutes = date.getMinutes();
		const ampm = hours >= 12 ? 'PM' : 'AM';
		const twelveHour = hours % 12 || 12; // Convert to  12-hour format
		const formattedDate = `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}`;
		return `${twelveHour.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}${ampm} ${formattedDate}`;
	}

	return (
		<div className='row m-0 p-0 mb-2 div-gradient-border'>
			{
				historyItem.transactionId === 'BsonNull' ? (
					<div className='col-md-3'>
						<div className='d-inline-block me-3' style={{ verticalAlign: 'middle' }}>
							<div className='td-item-image'>
								{
									imageSrc && (
										<ImageWithFallback
											src={imageSrc}
											fallbackSrc="/images/150.png"
											alt="Item Image"
										/>
									)
								}
							</div>
						</div>
						{
							<div className='d-inline-block' style={{ verticalAlign: 'middle' }}>
								<span style={{ fontSize: '9px' }}>{historyItem.itemClass ? historyItem.itemClass : ''}</span><br />
								<span style={{ fontSize: '14px', fontWeight: '600' }}>{historyItem.itemDisplayName ? historyItem.itemDisplayName : ''}</span>
							</div>
						}
					</div>
				) : (
					<div className='col-3'>
						<div className='mt-2 mb-2' style={{ marginLeft: '70px' }}>
							<span style={{ fontSize: '9px' }}>Transaction ID</span><br />
							<a style={{ fontSize: '14px' }} href={'https://mempool.space/tx/' + historyItem.transactionId} target='_blank'>{historyItem.transactionId ? historyItem.transactionId.substr(0, 4) + '...' + historyItem.transactionId.substr(-4) : ''}</a>
						</div>
					</div>
				)
			}

			{
				historyItem.transactionId === 'BsonNull' ? (
					<div className='col'>
						<div className='mt-2'>
							<span style={{ fontSize: '9px' }}>DIST</span><br />
							{!historyItem?.reason?.toLowerCase().includes("battle_royal") && <span style={{ fontSize: '14px' }}>{historyItem.dstCharacterName ? historyItem.dstCharacterName : ''}</span>}
						</div>
					</div>
				) : (
					<div className='col'></div>
				)
			}

			<div className='col'>
				<div className='mt-2'>
					<span style={{ fontSize: '9px' }}>ACTION</span><br />
					<span style={{ fontSize: '12px' }}>{historyItem.reason.replace(/_/g, ' ').toUpperCase()}</span>
				</div>
			</div>
			<div className='col'>
				<div className='mt-2'>
					<span style={{ fontSize: '9px' }}>TIME</span><br />
					<span style={{ fontSize: '7px' }}>{formatDateTime(historyItem.eventTime)}</span>
				</div>
			</div>
			<div className='col'>
				<div className='mt-3'>
					<img src='/images/zbit.png' style={{ height: '30px' }} className='me-2' alt='zbit-logo' />
					<span style={{ fontSize: '12px' }}>{historyItem.amount.toFixed(2)} ZBIT</span>
				</div>
			</div>
			<div className='col'>
				<div className='mt-2'>
					<span style={{ fontSize: '9px' }}>COUNT</span><br />
					<span style={{ fontSize: '14px' }}>{historyItem.count === 0 ? "1" : historyItem.count}</span>
				</div>
			</div>
			<div className='col'>
				<div className='mt-2'>
					<span style={{ fontSize: '9px' }}>NEW BALANCE</span><br />
					<span style={{ fontSize: '14px' }}>{historyItem.newBalances.toFixed(2)} ZBIT</span>
				</div>
			</div>
		</div>
	);
}

export default HistoryItem;