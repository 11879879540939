import React, { useState, useEffect } from 'react';
import '../App.css';
import { toast } from 'react-toastify';

import SideMenu from '../component/SideMenu';
import Header from '../component/Header';
import { useSelector } from 'react-redux';
import Form from 'react-bootstrap/Form';

import { withdrawCreditRequest, withdrawOnigiriRequest } from '../Api/marketplace_api';
import CreditBox from '../component/CreditBox';
import XverseWalletConnect from '../component/XverseWalletConnect';
import { GetRecommendedFees } from '../Api/mempoolApi';
import OnigiriBox from '../component/OnigiriBox';

function Withdraw() {

	const { playfabId, currentCharacter } = useSelector(state => state.login);
	const { xverseOrdinalsAddr, xverseOrdinalsSign, xversePaymentAddr, xversePaymentSign, xverseSignatureMsg, creditAmount, onigiriAmount } = useSelector(state => state.wallet);
	const [withdrawAmount, setWithdrawAmount] = useState(0);
	const [feeData, setFeeData] = useState(null);
	const [mempoolFee, setMempoolFee] = useState(0);
	const [txId, setTxId] = useState('');
	const [withdrawFee, setWithdrawFee] = useState(0);
	const [isPending, setIsPending] = useState(false);
	const [withdrawTxId, setWithdrawTxId] = useState('');
	const [withdrawItem, setWithdrawItem] = useState('zbit'); // zbit or onigiri

	useEffect(() => {
		GetRecommendedFees().then((feeData) => {
			console.log('', feeData);
			setFeeData(feeData);
			setMempoolFee(feeData.hourFee);
		})
			.catch(error => { toast(`getting mempool fee data is failed. (${error})`) })
	}, []);

	useEffect(() => {
		setWithdrawFee((mempoolFee * 380 + 2500 + 116 + 546) / 100000000);
	}, [mempoolFee]);

	useEffect(() => {
		withdrawItem === 'zbit' ? setWithdrawAmount(0) : setWithdrawAmount(777);
	}, [withdrawItem]);

	const handleWithdraw = async () => {
		// console.log(withdrawAmount, withdrawItem);
		if (withdrawAmount === '' || withdrawAmount === 0 || (withdrawItem === 'zbit' && withdrawAmount > creditAmount) || (withdrawItem === 'onigiri' && (withdrawAmount < 777 || withdrawAmount > onigiriAmount))) {
			return toast('Error: Amount is invalid.');
		}
		if (withdrawItem === 'zbit' && withdrawAmount > 1000) return toast('Error: The maximum withdrawal amount is 1000 ZBIT.')
		if (txId === '') {
			return toast('Error: Transaction ID is required.');
		}
		if (xverseOrdinalsSign === '' || xverseOrdinalsSign === null || xverseOrdinalsSign === undefined || xversePaymentSign === '' || xversePaymentSign === null || xversePaymentSign === undefined) {
			return toast('Error: Signature is invalid, sign wallet in connecting.');
		}
		setIsPending(true);
		if (withdrawItem === 'zbit') {
			withdrawCreditRequest(playfabId, parseFloat(withdrawAmount), mempoolFee, xverseOrdinalsAddr, xverseOrdinalsSign, xversePaymentAddr, xversePaymentSign, xverseSignatureMsg, txId).then(txId => {
				toast('Successfully sent your withdrawal request!');
				setWithdrawTxId(txId);
				setIsPending(false);
			}).catch(error => {
				toast(`Error: ${error}`);
				setIsPending(false);
			});
		}
		else {
			withdrawOnigiriRequest(playfabId, currentCharacter.characterId, parseInt(withdrawAmount), mempoolFee, xverseOrdinalsAddr, xverseOrdinalsSign, xversePaymentAddr, xversePaymentSign, xverseSignatureMsg, txId).then(txId => {
				toast('Successfully sent your withdrawal request!');
				setWithdrawTxId(txId);
				setIsPending(false);
			}).catch(error => {
				toast(`Error: ${error}`);
				setIsPending(false);
			});
		}
	};

	const handleClickAddress = (event) => {
		event.target.select();
		document.execCommand('copy');
		toast('Copied Address');
	};

	return (
		<div className='App'>
			<SideMenu />
			<div className="main-content">
				<Header />
				<div className='auth-page'>
					<div className="auth-content">
						<div className="auth-box">
							<label className='box-title'>WITHDRAWAL
								<img src='/images/turnoff.png' width={28} className='ms-2' alt='turnoff'/>
							</label>
							<select className='common-input-1 mb-3' value={withdrawItem} onChange={(e) => { setWithdrawItem(e.target.value) }}>
								<option value={'zbit'}>ZBIT</option>
								<option value={'onigiri'}>ONIGIRI</option>
							</select>
							<div className='text-center'>
								{withdrawItem === 'zbit' ? <CreditBox /> : <OnigiriBox />}
							</div>
							{
								withdrawItem === 'zbit' ?
									<label className='box-subtitle mt-3 mb-2'>You will get ZBIT ( <a href="https://unisat.io/runes/detail/ZBIT%E2%80%A2BLUE%E2%80%A2BITCOIN" target='_blank' rel="noreferrer">Runes</a> ).</label> :
									<label className='box-subtitle mt-3 mb-2'>You will get ONIGIRI ( <a href="https://unisat.io/runes/detail/SATORIA%E2%80%A2ONIGIRI" target='_blank' rel="noreferrer">Runes</a> ).</label>
							}

							<XverseWalletConnect style={{ width: '100%' }} type="Mainnet" />
							{
								xverseOrdinalsAddr && xverseOrdinalsAddr !== '' && withdrawTxId === '' ? (
									<>
										<input
											type='number'
											step={withdrawItem === 'zbit' ? '0.00000001' : '1'}
											className='common-input-1 mt-3'
											style={{ width: '100%' }}
											placeholder='Amount'
											autoComplete="off"
											name="amount"
											value={withdrawAmount}
											min={withdrawItem === 'zbit' ? 0 : 777}
											onChange={(e) => { setWithdrawAmount(e.target.value) }}
										/>
										{
											withdrawItem === 'onigiri' ? <label style={{ color: '#999999' }}>Minimum Amount: 777</label> : <></>
										}

										<div className="row mt-1 p-3" style={{ fontSize: '9px' }}>
											{
												feeData && (
													<>
														<div className="col">
															<Form.Check
																label={
																	<>
																		Fastest <br /> {`${feeData.fastestFee} sat/vb`}
																	</>
																}
																name="fee_check" type="radio" id={`fastestFee`}
																onChange={() => { setMempoolFee(feeData.fastestFee) }}
																checked={mempoolFee === feeData.fastestFee}
															/>
														</div>
														<div className="col">
															<Form.Check
																label={
																	<>
																		Half Hour <br /> {`${feeData.halfHourFee} sat/vb`}
																	</>
																}
																name="fee_check" type="radio" id={`halfHourFee`}
																onChange={() => { setMempoolFee(feeData.halfHourFee) }}
																checked={mempoolFee === feeData.halfHourFee}
															/>
														</div>
														<div className="col">
															<Form.Check
																label={
																	<>
																		Hour <br /> {`${feeData.hourFee} sat/vb`}
																	</>
																}
																name="fee_check" type="radio" id={`hourFee`}
																onChange={() => { setMempoolFee(feeData.hourFee) }}
																checked={mempoolFee === feeData.hourFee}
															/>
														</div>
														<div className="col">
															<Form.Check
																label={
																	<>
																		Economy <br /> {`${feeData.economyFee} sat/vb`}
																	</>
																}
																name="fee_check" type="radio" id={`economyFee`}
																onChange={() => { setMempoolFee(feeData.economyFee) }}
																checked={mempoolFee === feeData.economyFee}
															/>
														</div>
														<div className="col">
															<Form.Check
																label={
																	<>
																		Minimum <br /> {`${feeData.minimumFee} sat/vb`}
																	</>
																}
																name="fee_check" type="radio" id={`minimumFee`}
																onChange={() => { setMempoolFee(feeData.minimumFee) }}
																checked={mempoolFee === feeData.minimumFee}
															/>
														</div>
													</>
												)
											}
										</div>

										<label className='box-subtitle mt-3'>Send <b style={{ fontSize: '10px' }}>{withdrawFee} BTC</b> (Transaction Fee) to address below and submit the transaction ID after confirmed.</label>
										{
											xverseOrdinalsAddr && (
												<label className='box-subtitle mt-3 text-warning'>
													The transaction fee must be sent from your <b style={{ fontSize: '10px' }}>Xverse Wallet</b> addresses. <br />
													<a href={'https://mempool.space/address/' + xverseOrdinalsAddr} target='_blank' rel="noreferrer">{xverseOrdinalsAddr}</a> <br />
													<a href={'https://mempool.space/address/' + xversePaymentAddr} target='_blank' rel="noreferrer">{xversePaymentAddr}</a>
												</label>
											)
										}

										<input
											type='text'
											className='common-input-1 mt-3'
											style={{ width: '100%', cursor: 'pointer' }}
											placeholder='Transaction ID'
											autoComplete="off"
											name="depositAddress"
											value={'bc1ph0v3zn64ykrkmfzs83ewnw0rj7r67795x3l6d5tufh2zud3x8tuqkpu6hk'}
											readOnly
											onClick={(e) => { handleClickAddress(e) }}
										/>

										<input
											type='text'
											className='common-input-1 mt-3'
											style={{ width: '100%' }}
											placeholder='Transaction ID'
											autoComplete="off"
											name="amount"
											value={txId}
											onChange={(e) => { setTxId(e.target.value) }}
										/>
										<button className='auth-button mt-3' onClick={handleWithdraw} disabled={isPending}>{isPending ? 'Wait...' : 'WITHDRAW'}</button>
									</>
								) : (
									<label className='box-subtitle mt-3'>Connect to Xverse wallet to withdraw your {withdrawItem}.</label>
								)
							}

							{
								withdrawTxId !== '' && (
									<div className='mt-4 text-center'>
										<p>Show on wallet</p>
										<a href={`https://mempool.space/tx/${withdrawTxId}`}>{withdrawTxId}</a>
									</div>
								)
							}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default Withdraw;
