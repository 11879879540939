import { useState, useEffect } from 'react';
import '../assets/css/style.css';

const InvenCategoryTab = ({ selectedTabs, handleUpdateTabs }) => {
  const [activeTabs, setActiveTabs] = useState(selectedTabs);

  const tabs = [
    'all',
    'skill',
    'weapon',
    'hat',
    'facewear',
    'earring',
    'jacket',
    'overrall',
    'pants',
    'shoes',
    'cape',
    'necklace',
    'belt',
    'use',
    'stuff',
    'mint',
    'mount',
    'pets',
		'farm',
  ];

  const handleClickTab = (tab) => {
    if (tab === 'all') {
      setActiveTabs(['all']);
    }
    else {
      setActiveTabs(prevTabs => {
        if (prevTabs.includes(tab)) {
          if (prevTabs.length === 1) {
            return ['all'];
          }
          else {
            const actTabs = prevTabs.filter(t => t !== 'all');
            return actTabs.filter(t => t !== tab);
          }
        } else {
          const actTabs = prevTabs.filter(t => t !== 'all');
          return [...actTabs, tab];
        }
      });
    }
    handleUpdateTabs(activeTabs);
  }

  useEffect(() => {
    handleUpdateTabs(activeTabs);
  }, [activeTabs]);

  return (
    <div className="filter-menu">
      <ul>
        {
          tabs.map((tab, index) => (
            <li key={index} className={activeTabs && activeTabs.includes(tab) ? 'active' : ''} onClick={() => { handleClickTab(tab) }}>{tab.toUpperCase()}</li>
          ))
        }
      </ul>
    </div>
  );
};
export default InvenCategoryTab;
