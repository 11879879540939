import { combineReducers, legacy_createStore as createStore } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web
import walletReducer from './walletReducer';
import loginReducer from './loginReducer';

const rootReducer = combineReducers({
    wallet: walletReducer,
    login: loginReducer,
});

const persistConfig = {
    key: 'root',
    storage,
    blacklist: ['wallet']
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = createStore(persistedReducer);
export const persistor = persistStore(store);