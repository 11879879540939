import { Image } from 'react-bootstrap';

const UpgradeSlot = ({ invenItem }) => {
    return (
        <div className='text-center' style={{ width: '100%' }}>
            <div>
                <span>GEMS</span>
                <span className='ms-3'>{invenItem.upgradeSlots ? invenItem.upgradeSlots.length : 0}/10</span>
            </div>
            <div>
                {
                    invenItem.upgradeSlots && (
                        invenItem.upgradeSlots.map((upgradeSlot, index) => (
                            upgradeSlot.applyBooster > 0 ?
                                (
                                    <div className='d-inline-block me-1'>
                                        <Image
                                            src={`https://submitscore33fafc.blob.core.windows.net/item-icons/${upgradeSlot.itemId}.png`}
                                            width={"25px"}
                                            height={"25px"}
                                            key={`upgrade_slot_${index}_${invenItem.id}`} />
                                    </div>
                                ) :
                                (
                                    <div className='d-inline-block me-1'>
                                        <Image src={`/images/broken glass.png`}
                                            width={"25px"}
                                            height={"25px"}
                                            key={`upgrade_slot_${index}_${invenItem.id}`} />
                                    </div>
                                )
                        ))
                    )
                }
            </div>
        </div>
    );
}

export default UpgradeSlot;