import React, { useState, useEffect } from 'react';
import { Modal, Dropdown } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { fetchCharacterData, getUserProfile, fetchCharacters, claimOrdLandItemFromWallet } from '../Api/marketplace_api';
import { toast } from 'react-toastify';

const WalletOrdinalsLand = ({ ordinalsItem, updateEvent }) => {
	const [imageSrc, setImageSrc] = useState();
	// const [claimProfile, setClaimProfile] = useState([]);
	const [isShowClaimModal, setIsShowClaimModal] = useState(false);
	const [characters, setCharacters] = useState([]);
	const [selectedCharacter, setSelectedCharacter] = useState(null);
	const [isSaving, setIsSaving] = useState(false);
	const [ownerCharacter, setOwnerCharacter] = useState(null);
	const { playfabId, currentCharacter } = useSelector(state => state.login);

	useEffect(() => {
		try {
			setImageSrc(`/images/land.png`);
		}
		catch {
			setImageSrc(`/images/150.png`);
		}

		if (ordinalsItem.claimCharacterID) {
			fetchCharacterData(ordinalsItem.claimPlayFabID, ordinalsItem.claimCharacterID)
				.then(characterData => { setSelectedCharacter(characterData); setOwnerCharacter(characterData) })
				.catch(error => { setSelectedCharacter(currentCharacter) });
		}
		else {
			setSelectedCharacter(currentCharacter);
		}

		// getUserProfile(ordinalsItem.claimPlayFabID)
		// 	.then((result) => {
		// 		setClaimProfile(result);
		// 	})
		// 	.catch(error => {
		// 		console.log("error in getUserProfile:", error);
		// 	});
	}, [ordinalsItem]);

	useEffect(() => {
		if (playfabId) {
			fetchCharacters(playfabId)
				.then(caracters => {
					setCharacters(caracters);
				})
				.catch(error => {
					setCharacters([]);
				});
		}
	}, [playfabId]);

	const handleClaim = () => {
		setIsSaving(true);
		claimOrdLandItemFromWallet(ordinalsItem.id, playfabId, selectedCharacter.characterId)
			.then(result => {
				toast("Success! Claimed");
				setIsShowClaimModal(false);
				setIsSaving(false);
				updateEvent();
			})
			.catch(error => {
				toast("Error! " + error);
				setIsSaving(false);
			});
	}

	const handleClose = () => {
		setIsShowClaimModal(false);
	}

	const handleSelectCharacter = async (character) => {
		setSelectedCharacter(character);
	};

	return (
		<div className='row m-0 p-0 mb-2 div-gradient-border'>
			<div className='col-sm-4'>
				<div className='d-inline-block me-3' style={{ verticalAlign: 'middle' }}>
					<div className='td-item-image'>
						<img src={imageSrc} alt='item' />
					</div>
				</div>

				<div className='d-inline-block' style={{ verticalAlign: 'middle' }}>
					<span style={{ fontSize: '9px' }}>NAME</span><br />
					<div className="d-flex">
						<span className='truncate-text' style={{ fontSize: '14px', fontWeight: '600', maxWidth: "250px" }}>{ordinalsItem.landName}</span>
						{ordinalsItem?.claimCharacterID && !ordinalsItem?.landInstance &&
							<img src={"images/icon_destroy.png"} alt='destroy' className='ms-2 m-auto w-16px h-16px' />}
					</div>
				</div>
			</div>
			<div className='col-sm-3'>
				<div className='mt-2'>
					<span style={{ fontSize: '9px' }}>INSCRIPTION ID</span><br />
					<span style={{ fontSize: '14px' }} title={ordinalsItem.inscriptionId}>
						{ordinalsItem.inscriptionId.substr(0, 4) + '...' + ordinalsItem.inscriptionId.substr(-4)}
					</span>
				</div>
			</div>
			<div className='col-sm-3'>
				<div className='mt-2'>
					<span style={{ fontSize: '9px' }}>OWNER</span><br />
					<span style={{ fontSize: '14px' }}>{ownerCharacter ? ownerCharacter.characterName : ''}</span>
				</div>
			</div>
			<div className='col-sm-2 text-end'>
				<button className='button-gradient-1 mt-2' onClick={() => { setIsShowClaimModal(true) }}>CLAIM</button>
			</div>

			<Modal show={isShowClaimModal} onHide={handleClose} backdrop={true} className='modal-cm'>
				<Modal.Body>
					<h4 className='text-center'>CLAIM</h4>
					<div className='text-center mt-5' style={{ fontSize: '18px' }}>
						<label className='mb-2'>{ordinalsItem.landName ? ordinalsItem.landName : ''}</label>
						<div className='mt-2 character-selector'>
							<Dropdown>
								<Dropdown.Toggle variant="" id="dropdown-basic">
									<span className='me-1'>{selectedCharacter ? selectedCharacter.characterName : ''}</span>
								</Dropdown.Toggle>

								{
									characters?.length > 0 &&
									(
										<Dropdown.Menu>
											{
												characters.map((character, index) => (
													<Dropdown.Item key={character.characterId} onClick={() => { handleSelectCharacter(character) }}>{character.characterName}</Dropdown.Item>
												))
											}
										</Dropdown.Menu>
									)
								}

							</Dropdown>
						</div>
					</div>

					<div className='text-center mt-5'>
						<button
							className='button-gradient-1'
							onClick={handleClaim}
							disabled={isSaving}
						>
							{isSaving ? 'Wait...' : 'CLAIM'}
						</button>
					</div>
				</Modal.Body>
			</Modal>
		</div>
	);
}

export default WalletOrdinalsLand;