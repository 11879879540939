import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import WalletOrdinalsItem from '../component/WalletOrdinalsItem';
import { LoadInscriptionGameItemsFromWalletAddr } from '../Api/marketplace_api';

function WalletOrdinalsItems() {
	const [ordinalsItems, SetOrdinalsItems] = useState(null);
	const { xverseOrdinalsAddr, xverseOrdinalsSign, xversePaymentAddr, xversePaymentSign, xverseSignatureMsg } = useSelector(state => state.wallet);

	const fetchItems = () => {
		LoadInscriptionGameItemsFromWalletAddr(xverseOrdinalsAddr, xverseOrdinalsSign, xversePaymentAddr, xversePaymentSign, xverseSignatureMsg).then((result => {
			SetOrdinalsItems(result);
		})).catch(error => {
		});
	};

	useEffect(() => {
		if (xverseOrdinalsAddr) {
			fetchItems();
		}
	}, [xverseOrdinalsAddr]);

	return (
		<>
			{xverseOrdinalsAddr &&
				(ordinalsItems ?
					(<>
						{ordinalsItems.length === 0 ?
							(<div className='text-center mt-5'> No Item </div>) :
							(ordinalsItems.map((ordinalsItem, index) => (<WalletOrdinalsItem ordinalsItem={ordinalsItem} updateEvent={fetchItems} key={index} />)))
						}
					</>) :
					(<div className='row m-0 p-0 mt-2'>
						<div className='col-sm-2 p-2'>
							<span className="sr-only">Loading Items...</span>
						</div>
						<div className='col-sm-1 p-1'>
							<div className="spinner-border text-primary" role="status"></div>
						</div>
					</div>)
				)
			}
		</>
	);
}

export default WalletOrdinalsItems;