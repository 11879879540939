import React, { useState, useEffect } from 'react';
import ImageWithFallback from './ImageWithFallback';

const TradeHistoryItem = ({ tradeHistoryItem }) => {
	const [imageSrc, setImageSrc] = useState(null);

	useEffect(() => {

		try {
			if(tradeHistoryItem.count >= 0){
				setImageSrc(`https://submitscore33fafc.blob.core.windows.net/item-icons/${tradeHistoryItem.itemId}.png`);
			}
			else{
				setImageSrc(`https://submitscore33fafc.blob.core.windows.net/skill-icons/${tradeHistoryItem.itemId}.png`);
			}			
		}
		catch {
			setImageSrc(`/images/150.png`);
		}
	}, [tradeHistoryItem]);

	const formatDateTime = (input) => {
		const date = new Date(input);
		const hours = date.getHours();
		const minutes = date.getMinutes();
		const ampm = hours >= 12 ? 'PM' : 'AM';
		const twelveHour = hours % 12 || 12; // Convert to  12-hour format
		const formattedDate = `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}`;
		return `${twelveHour.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}${ampm} ${formattedDate}`;
	}

	return (
		<div className='row m-0 p-0 mb-2 div-gradient-border'>
			<div className='col-sm-3'>
				<div className='d-inline-block me-3' style={{ verticalAlign: 'middle' }}>
					<div className='td-item-image'>
						{
							imageSrc && (
								<ImageWithFallback
									src={imageSrc}
									fallbackSrc="/images/150.png"
									alt="Item Image"
								/>
							)
						}
					</div>
				</div>
				{
					<div className='d-inline-block' style={{ verticalAlign: 'middle' }}>
						<span style={{ fontSize: '9px' }}>{tradeHistoryItem.itemClass ? tradeHistoryItem.itemClass : ''}</span><br />
						<span style={{ fontSize: '14px', fontWeight: '600' }}>{tradeHistoryItem.displayName ? tradeHistoryItem.displayName : ''}</span>
					</div>
				}
			</div>
			<div className='col-sm-2'>
				<div className='mt-2'>
					<span style={{ fontSize: '9px' }}>SELLER</span><br />
					<span style={{ fontSize: '14px' }}>{tradeHistoryItem.sellerCharacterName}</span>
				</div>
			</div>
			<div className='col-sm-2'>
				<div className='mt-2'>
					<span style={{ fontSize: '9px' }}>BUYER</span><br />
					<span style={{ fontSize: '14px' }}>{tradeHistoryItem.buyerCharacterName}</span>
				</div>
			</div>
			<div className='col-sm-2'>
				<div className='mt-2'>
					<span style={{ fontSize: '9px' }}>TIME</span><br />
					<span style={{ fontSize: '7px' }}>{formatDateTime(tradeHistoryItem.finalizedTime)}</span>
				</div>
			</div>
			<div className='col-sm-2'>
				<div className='mt-3'>
					<img src='/images/zbit.png' style={{ height: '30px' }} className='me-2' alt='zbit-logo' />
					<span style={{ fontSize: '12px' }}>{tradeHistoryItem.price.toFixed(2)} ZBIT</span>
				</div>
			</div>
			<div className='col-sm-1'>
				<div className='mt-2'>
					<span style={{ fontSize: '9px' }}>COUNT</span><br />
					<span style={{ fontSize: '14px' }}>{tradeHistoryItem.count <= 0 ? "1" : tradeHistoryItem.count}</span>
				</div>
			</div>
		</div>
	);
}

export default TradeHistoryItem;