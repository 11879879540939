import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import StoreItemCard from './StoreItemCard';
import { useState, useEffect } from 'react';
import ReactPaginate from 'react-paginate';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import { fetchStoreItems, PlaceOrderIdOnStoreItem, PlaceTransactionIdOnStoreItem } from '../Api/marketplace_api';
import { CreateInscriptionOrder, DEV_ADDRESS_ZBIT } from '../Api/unisatApi';
import { GetRecommendedFees } from '../Api/mempoolApi';
import PlaceOrderModal from '../component/PlaceOrderModal';
import PayTokenModal from '../component/PayTokenModal';
import PageTitle from './PageTitle';
import InvenCategoryTab from './InvenCategoryTab';
import SearchBar from './SearchBar';
import CharacterSelector from './CharacterSelector';
import '../assets/css/style.css';

const MarketPlaceUI = () => {

	const walletAddr = useSelector(state => state.wallet.walletAddr);
	const zbitData = useSelector(state => state.wallet.zbitData);
	const { playfabId, currentCharacter } = useSelector(state => state.login);

	const [storeItems, setStoreItems] = useState([]);
	const [showPaymentModal, setShowPaymentModal] = useState(false);
	const [showPayTokenModal, setShowPayTokenModal] = useState(false);

	const [curAciveOrder, setCurAciveOrder] = useState(null);
	const [selectedStoreItem, setSelectedStoreItem] = useState(null);

	const [feeData, setFeeData] = useState(null);
	const [mempoolFee, setMempoolFee] = useState(0);
	const [selectedInscription, setSelectedInscription] = useState(null);
	const [placingActionFlag, setPlacingActionFlag] = useState(false);

	const [totalCount, setTotalCount] = useState(0);
	const [pageSize, setPageSize] = useState(24);
	const [pageNum, setPageNum] = useState(0);

	const [searchValue, setSearchValue] = useState('');
	const [activeTabs, setActiveTabs] = useState(['all']);
	const [sort, setSort] = useState('NL');
	const [isLoading, setIsLoading] = useState(false);

	const refreshCurrentPage = () => {
		setIsLoading(true);
		setStoreItems([]);
		let searchKey = "";
		if (!activeTabs.includes('all')) {
			searchKey = activeTabs.join('|');
		}
		fetchStoreItems(pageNum, pageSize, searchKey, searchValue, sort).then((result) => {
			setStoreItems(result.stores);
			setTotalCount(result.totalCount);
			setIsLoading(false);
		})
			.catch(error => {
				console.log("error in fetchStoreItems:", error)
				setIsLoading(false);
			});
	}

	useEffect(() => {
		// console.log(activeTabs);
		refreshCurrentPage();
	}, [pageNum, activeTabs, searchValue, sort]);

	useEffect(() => {
		if (selectedStoreItem !== null) {
			if (placingActionFlag === true) {
				if (!zbitData) {
					toast("Please connect wallet first");
					setSelectedStoreItem(null);
				}
				else {
					if (selectedStoreItem.price > zbitData.availableBalance) {
						toast("Insufficient balances");
						setSelectedStoreItem(null);
					}
					else {
						GetRecommendedFees().then((feeData) => {
							setFeeData(feeData);
							setMempoolFee(feeData.hourFee);
						})
							.catch(error => { toast(`getting mempool fee data is failed. (${error})`) })
					}
				}
			}
		}

	}, [selectedStoreItem]);

	useEffect(() => {
		if (mempoolFee > 0) {
			if (selectedStoreItem && zbitData) {
				CreateInscriptionOrder(walletAddr, selectedStoreItem.price, mempoolFee).then((order) => {
					console.log("InscriptionOrderCreated:", order, selectedStoreItem);
					setCurAciveOrder(order);
					setShowPaymentModal(true);
				}).catch((error) => {
					toast(error);
				});
			}
		}
	}, [mempoolFee]);

	useEffect(() => {
		if (selectedInscription !== null) {
			setShowPayTokenModal(true);
		}
	}, [selectedInscription]);

	const paymentDoneEvent = () => {
		PlaceOrderIdOnStoreItem(selectedStoreItem.id, curAciveOrder.orderId).then(value => {
			selectedStoreItem.orderId = curAciveOrder.orderId;
			handleModalClose();
			refreshCurrentPage();
		}).catch(error => {
			setShowPaymentModal(false);
			toast(error);
		});
	}
	const handlePayWithWallet = async () => {
		if (typeof window.unisat === 'undefined') {
			window.open("https://unisat.io/download", "_blank");
		}
		else {
			try {
				let txid = await window.unisat.sendBitcoin(curAciveOrder.payAddress, curAciveOrder.amount);
				console.log(txid);
				paymentDoneEvent();
			} catch (error) {
				console.log(error);
			}
		}
	};

	// const PlaceOrder = (storeItem) => {
	// 	console.log("PlaceOrder", storeItem, playfabId, currentCharacter.characterId);
	// 	setPlacingActionFlag(true);
	// 	setSelectedStoreItem(storeItem);
	// }

	// const PayToken = (storeItem, paymentInscription, feeInscription) => {
	// 	console.log("PayToken", storeItem, paymentInscription, feeInscription);
	// 	setPlacingActionFlag(false);
	// 	setSelectedStoreItem(storeItem);
	// 	setSelectedInscription([paymentInscription, feeInscription]);
	// }

	const handlePayTokenWithWallet = async () => {
		if (selectedInscription && selectedStoreItem && currentCharacter) {
			console.log("handlePayTokenWithWallet:", selectedInscription, selectedStoreItem, DEV_ADDRESS_ZBIT, playfabId, currentCharacter.characterId)
			try {
				let transactions = [];
				if (selectedInscription[0].address !== selectedStoreItem.recipient) {
					console.log("send Payment Inscription", selectedInscription[0], selectedStoreItem.recipient);
					let txid = await window.unisat.sendInscription(selectedStoreItem.recipient, selectedInscription[0].inscriptionId);
					console.log("send Payment Inscription", txid);
					transactions.push(txid);
				}
				if (selectedInscription[1].address !== DEV_ADDRESS_ZBIT) {
					console.log("send Fee Inscription", selectedInscription[1], DEV_ADDRESS_ZBIT);
					let txid = await window.unisat.sendInscription(DEV_ADDRESS_ZBIT, selectedInscription[1].inscriptionId);
					console.log("send Fee Inscription", txid);
					transactions.push(txid);
				}

				PlaceTransactionIdOnStoreItem(selectedStoreItem.id, transactions, playfabId, currentCharacter.characterId).then(value => {
					setShowPayTokenModal(false);
					refreshCurrentPage();
				}).catch(error => {
					setShowPayTokenModal(false);
					toast(error);
				});
			} catch (e) {
				console.log(e);
				setShowPayTokenModal(false);
			}

		}
	}

	const handleModalClose = () => {
		setShowPaymentModal(false);
		setShowPayTokenModal(false);
		setSelectedStoreItem(null);
		setSelectedInscription(null);
		setMempoolFee(0);
	};

	const handlePageClick = (event) => {
		setStoreItems([]);
		setPageNum(event.selected);
	};

	const handleUpdateTabs = (tabs) => {
		setTotalCount(0);
		setPageNum(0);
		setActiveTabs(tabs);
	};

	const updateSearchValue = (searchValue) => {
		setSearchValue(searchValue);
	};

	return (
		<>
			<div className="row m-0 p-0">
				<div className="col-sm-6 col-md-8">
					<SearchBar updateSearchValue={updateSearchValue} />
				</div>
				<div className="col-sm-6 col-md-4 text-end p-0">
					<CharacterSelector />
				</div>
			</div>

			<PageTitle />
			<InvenCategoryTab selectedTabs={activeTabs} handleUpdateTabs={handleUpdateTabs} />
			<div className='row'>
				<div className='col-md-9'>
					<div className='pagination-div mt-3 ms-2' >
						<ReactPaginate
							breakLabel="..."
							nextLabel="next >"
							onPageChange={handlePageClick}
							pageRangeDisplayed={5}
							pageCount={parseInt((totalCount - 1) / pageSize) + 1}
							previousLabel="< prev"							
							renderOnZeroPageCount={null}
						/>
					</div>
				</div>
				<div className='col-md-3 text-right'>
					<select className='mt-3' style={{ maxWidth: '200px' }} value={sort} onChange={(e) => { setSort(e.target.value) }}>
						<option value='NL'>Newest Listing</option>
						<option value='OL'>Oldest Listing</option>
						<option value='LP'>Lowest Price</option>
						<option value='HP'>Highest Price</option>
					</select>
				</div>
			</div>

			<div>
				{
					isLoading ?
						(
							<div className='text-center mt-5'> Loading Items ...</div>
						)
						:
						(
							storeItems.length === 0 ?
								(
									<div className='text-center mt-5'> No Item </div>
								) :
								(
									storeItems.map((storeItem, index) =>
									(
										<StoreItemCard
											storeItem={storeItem}
											key={index}
											updateEvent={refreshCurrentPage}
										/>
									))
								)
						)

				}
			</div>
			<PlaceOrderModal
				show={showPaymentModal}
				curAciveOrder={curAciveOrder}
				feeData={feeData}
				mempoolFee={mempoolFee}
				handleModalClose={handleModalClose}
				setMempoolFee={setMempoolFee}
				handlePayWithWallet={handlePayWithWallet}
			/>
			<PayTokenModal
				show={showPayTokenModal}
				handlePayTokenWithWallet={handlePayTokenWithWallet}
				handleModalClose={handleModalClose}
			/>
		</>
	);

}

export default MarketPlaceUI;