import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal, Image } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { buyShopItem, getUserCredit, getItemCatalog } from '../Api/marketplace_api';
import '../assets/css/style.css'



const ShopItemCard = ({ shopItem, updateEvent }) => {
	const dispatch = useDispatch();
	const {
		creditAmount,
	} = useSelector(state => state.wallet);
	const {
		playfabId,
		currentCharacter,
	} = useSelector(state => state.login);
	const [isSaving, setIsSaving] = useState(false);
	const [imageSrc, setImageSrc] = useState();
	const [isShowItem, setIsShowItem] = useState(false);
	const [buyQty, setBuyQty] = useState(1);
	const [shopItemProerty, setShopItemProerty] = useState(null);

	useEffect(() => {
		if (shopItem) {
			try {
				getItemCatalog(shopItem.itemId)
					.then(item => {
						if (item) {
							setShopItemProerty(item);
						}
					})
					.catch(error => {
						setShopItemProerty(null);
					});
				setImageSrc(`https://submitscore33fafc.blob.core.windows.net/item-icons/${shopItem.itemId}.png`);
			}
			catch {
				setImageSrc(`/images/150.png`);
			}
		}
	}, [shopItem]);

	const handleClose = () => {
		setIsShowItem(false);
	}

	const refreshCreditAmount = () => {
		getUserCredit(playfabId, currentCharacter.characterId).then(value => {
			if (value && value.amount) {
				dispatch({ type: 'SET_CREDIT_AMOUNT', payload: value.amount });
			}
		}).catch(error => {
			console.log(error);
		});
	}

	const handleBuyItem = () => {
		if (!playfabId) {
			return toast.warning("Please log in first!");
		}
		if (creditAmount >= shopItem.price) {
			setIsSaving(true);
			buyShopItem(shopItem.id, buyQty, playfabId, currentCharacter.characterId).then(result => {
				toast("Success!");
				refreshCreditAmount();
				setIsSaving(false);
				setIsShowItem(false);
				updateEvent();
			}).catch(error => {
				console.log(error);
				setIsSaving(false);
				setIsShowItem(false);
			});
		}
		else {
			toast("Error! Insufficient credits.");
		}
	}

	return (
		<div className='div-item-card'>
			<div className='div-item-image'>
				<Image
					src={imageSrc}
				/>
			</div>
			<div className='mb-2'>
				{shopItemProerty && (shopItemProerty.displayName)}
			</div>
			<div style={{ fontSize: '9px' }}>
				<span className='mb-3'>Quantity: </span>
				<span>{shopItem && (shopItem.count)}</span>
				<br />
				<span>{shopItem.price} ZBIT</span>
				<br />
				<button className='button-gradient-1 btn-small mt-2'
					onClick={() => {
						if (playfabId) {
							setIsShowItem(true);
						} else {
							toast.warning("Please log in first!");
						}
					}}>
					BUY NOW
				</button>
			</div>
			<div style={{ position: 'absolute', bottom: '20px', width: '100%' }}>

				<Modal show={isShowItem} onHide={handleClose} backdrop={true} className='modal-cm'>
					<Modal.Body>
						<div>
							<div className='text-center'>
								<div className='div-item-image'>
									<Image
										src={imageSrc}
									/>
								</div>
								<h6 className='mb-3'>{shopItemProerty && (shopItemProerty.displayName)}</h6>
							</div>
							<div>
								<span className='me-3'>Available Quantity: </span>
								<span>{shopItem && (shopItem.count)}</span>
							</div>
							<div>
								<span className='me-3'>Price: </span>
								<span>{shopItem && (shopItem.price)} ZBIT</span>
							</div>

							<label className='mt-3'>Quantity:</label>
							<input
								type="number"
								className="common-input-1 d-block"
								style={{ width: '100%' }}
								placeholder="Quantity"
								min='1'
								max={shopItem && (shopItem.count)}
								value={buyQty}
								onChange={(e) => { setBuyQty(e.target.value) }}
							/>
						</div>
						<div className='text-center mt-4'>
							<button
								className='button-gradient-1'
								disabled={isSaving || creditAmount < shopItem.price}
								title={creditAmount < shopItem.price ? 'Insufficient credits' : ''}
								onClick={handleBuyItem}
							>
								{isSaving ? 'Wait...' : 'BUY NOW'}
							</button>

						</div>
					</Modal.Body>
				</Modal>
			</div>
		</div>
	);
}

export default ShopItemCard;