import React from 'react';
import { useEffect, useState } from 'react';
import '../assets/css/SideMenu.css';
import { Link, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import CreditBox from './CreditBox';
import OnigiriBox from './OnigiriBox';
import { getUserCredit, getUserOnigiri } from '../Api/marketplace_api';

const SideMenu = ({ zbitWalletInfo }) => {
	const dispatch = useDispatch();
	const location = useLocation();
	const { playfabId, currentCharacter } = useSelector(state => state.login);
	const [showMenu, setShowMenu] = useState(false);
	const [showToggleButton, setShowToggleButton] = useState(false);

	const refreshCreditAmount = () => {
		getUserCredit(playfabId, currentCharacter.characterId).then(value => {
			if (value && value.amount) {
				dispatch({ type: 'SET_CREDIT_AMOUNT', payload: value.amount });
			}
		}).catch(error => {
			console.log(error);
		});
	}

	const refreshOnigiriAmount = () => {
		getUserOnigiri(playfabId, currentCharacter.characterId).then(value => {
			dispatch({ type: 'SET_ONIGIRI_AMOUNT', payload: value });
		}).catch(error => {
			console.log(error);
		});
	}

	useEffect(() => {
		if (currentCharacter && playfabId) {
			refreshCreditAmount();
			refreshOnigiriAmount();
		}
		const handleResize = () => {
			setShowToggleButton(window.innerWidth < 768);
		};
		handleResize();
		window.addEventListener('resize', handleResize);

		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, [playfabId, currentCharacter]);

	const toggleMenu = () => {
		setShowMenu(!showMenu);
	}

	return (
		<div>
			{showToggleButton && (
				<button className="toggle-menu-button mt-3" style={{ marginLeft: '30px' }} onClick={toggleMenu}><img src='/images/menu_icon.svg' alt="Menu" /></button>
			)}
			<div className={showMenu ? 'side-menu active' : 'side-menu'}>
				<div>
					<img src='/images/logo.png' alt="Logo" className="logo" />
					<Link to="/">
						<button className={location.pathname === '/' ? 'menu-button active' : 'menu-button'}>MY GAMES</button>
					</Link>
					<Link to="/marketplace">
						<button className={location.pathname === '/marketplace' ? 'menu-button active' : 'menu-button'}>MARKETPLACE</button>
					</Link>
					{/* <Link to="/marketplace-ord">
						<button className={location.pathname === '/marketplace-ord' ? 'menu-button active' : 'menu-button'}>ORDINAL MARKETPLACE</button>
					</Link> */}
					<Link to="/shop">
						<button className={location.pathname === '/shop' ? 'menu-button active' : 'menu-button'}>SHOP</button>
					</Link>
					<Link to="/trade">
						<button className={location.pathname === '/trade' ? 'menu-button active' : 'menu-button'}>TRADES</button>
					</Link>
					<br />
					<br />
					<Link to="/inventory">
						<button className={location.pathname === '/inventory' ? 'menu-button active' : 'menu-button'}>INVENTORY</button>
					</Link>
					<Link to="/skill">
						<button className={location.pathname === '/skill' ? 'menu-button active' : 'menu-button'}>SKILL</button>
					</Link>
					<Link to="/zbitloot">
						<button className={location.pathname === '/zbitloot' ? 'menu-button active' : 'menu-button'}>ZBIT LOOT</button>
					</Link>
				</div>
				{playfabId && <div>
					<Link to="/history">
						<button className={location.pathname === '/history' ? 'menu-button active' : 'menu-button'}>HISTORY</button>
					</Link>
					<Link to="/withdraw">
						<button className={location.pathname === '/withdraw' ? 'menu-button active' : 'menu-button'}>WITHDRAW</button>
					</Link>
					<Link to="/refill">
						<button className={location.pathname === '/refill' ? 'menu-button active' : 'menu-button'}>REFILL ZBIT</button>
					</Link>
					<CreditBox />
					<div className='mt-2'></div>
					<OnigiriBox />
				</div>}
			</div>
		</div>
	);
}

export default SideMenu;
